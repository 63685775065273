import {
  Button,
  ClickAwayListener,
  Container,
  Drawer,
  Grow,
  IconButton,
  Modal,
  Slide,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import LoadingIcon from "../../LoadingIcon";
import {
  ArrowForward,
  Close,
  Notifications,
  NotificationsOff,
  PersonAdd,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import useIsMobileDevice from "../../../utils/mobileCheck";
import hexToRgb from "../../../utils/hexToRgba";
import iconNOt from "../../../assets/Group 1436.png";

import astrounautHoldingPhone from "../../../assets/posesPNG/astronout-holding-phone.png";

import "./ContentContainer.css";

export const ContentContainer = ({
  styles,
  children,
  notificationsVisible,
  setNotificationsVisible,
  loading,
  notifications,
  setNotifications,
}) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNotificationTitle = (title, name) => {
    switch (title) {
      case "Novo Lead Recebido":
        return (
          <>
            <strong>{name}</strong> agora é sua Lead. Veja o resumo e
            classificação de interesse.
          </>
        );

      default:
        return <>{title}</>;
    }
  };

  const handleClick = async (notification) => {
    try {
      await api.put(
        `/talents/notifications/${notification.notificationId}/readed`
      );

      setNotifications((currentNotifications) => {
        const updatedNotications = currentNotifications.reduce(
          (acc, currentItem) => {
            if (currentItem.notificationId === notification.notificationId) {
              acc.push({ ...currentItem, readed: true });
            } else {
              acc.push(currentItem);
            }
            return acc;
          },
          []
        );
        return updatedNotications;
      });

      //console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const readAll = async () => {
    try {
      //  await api.put(
      //     `/talents/notifications/${notification.notificationId}/readed`
      //   );

      // console.log(notifications);

      setNotifications((currentNotifications) => {
        const updatedNotications = currentNotifications.reduce(
          (acc, currentItem) => {
            if (currentItem.readed === false) {
              acc.push({ ...currentItem, readed: true });
            } else {
              acc.push(currentItem);
            }
            return acc;
          },
          []
        );
        return updatedNotications;
      });

      //console.log(data);
    } catch (error) {}
  };

  const isMobile = useIsMobileDevice();

  const NotificationCard = ({ notification }) => {
    return (
      <Box
        sx={{
          backgroundColor: "white",
          // borderRadius: "10px",
          // marginBottom: "5px",
          display: "flex",
          minHeight: "50px",
          justifyContent: "space-around",
          alignItems: "center",
          borderBottom: "1px solid rgba(191, 183, 184, 0.2)",
          width: "100%",
          cursor: "pointer",
          opacity: notification?.readed ? "30%" : "100%",
          ":hover": {
            opacity: notification?.readed ? "45%" : "75%",
            backgroundColor: `${hexToRgb("#57BAB8", 0.15)}`,
          },
          flexDirection: "column",
          paddingTop: "20px",
        }}
        onClick={() => {
          handleClick(notification);
        }}
      >
        {/* <div
          style={{ display: "flex", width: "15%", justifyContent: "center" }}
        >
          {notification.title === "Novo Lead Recebido" ? (
            <PersonAdd color="primary" />
          ) : (
            <Notifications color="primary" />
          )}
        </div> */}
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: "8px",
            alignItems: "center",
            // justifyContent: "center",
          }}
        >
          <img
            src={iconNOt}
            alt=""
            style={{ width: "19.03px", height: "19.03px" }}
          />
          <div className="titleNotifications">{notification.title}</div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            // justifyContent: "center",
            marginTop: "10px",
            marginBottom: "20px",
          }}
        >
          <div className="subTitleotifications">{notification.description}</div>
        </div>
        {/* {notification.title === "Novo Lead Recebido" ? (
          <div
            style={{ display: "flex", width: "15%", justifyContent: "center" }}
          >
            <ArrowForward color="primary" />
          </div>
        ) : (
          <div
            style={{ display: "flex", width: "15%", justifyContent: "center" }}
          ></div>
        )} */}
      </Box>
    );
  };

  function isMobileDevice() {
    return window.innerWidth <= 800;
  }

  return (
    <div
      component="main"
      sx={{
        flexGrow: 1,
        // minHeight: "100vh",
        overflow: "hidden",
        overflowX: "hidden",
        // paddingTop: 12,
        display: "flex",
        justifyContent: "center",
        // marginBottom: '40px'
      }}
    >
      
      <div style={{ ...styles }} className="containerBoxCenterCompanies">
        {children}
      </div>
    </div>
  );
};
