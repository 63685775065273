import React from "react";
import VideoThumbnail from "./VideoThumbnail";
import "./SelfieLetter.css";

const SelfieLetter = ({ talents }) => {
  return (
    <>
      {talents?.isSelfieLetterFilled ? (
        <div className="boxSelfieLetter">
          <div className="box1">
            <div>Resumo da Selfie Letter</div>
            <span>
              Olá! Sou um profissional apaixonado por marketing digital e
              análise de dados, com experiência sólida em gestão de equipes e
              implementação de estratégias inovadoras. Minha jornada inclui:
            </span>
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <li>5 anos de experiência em SEO e otimização de conteúdo.</li>
              <li>Expertise em ferramentas como Google Analytics e SEMrush.</li>
              <li>
                Liderança de projetos bem-sucedidos de e-mail marketing e
                automação.
              </li>
              <li>
                Habilidade comprovada em melhorar métricas de engajamento e
                conversão.
              </li>
            </div>
            <span>
              Sou conhecido por minha abordagem analítica e criativa, sempre
              buscando soluções que combinem dados concretos com insights
              originais. Minha comunicação clara e habilidade de trabalhar em
              equipe me permitem colaborar efetivamente com diversos
              departamentos.
            </span>
          </div>

          <div className="box2">
            <div>Vídeo da Selfie Letter</div>
            {talents?.selfieLetter && (
              <VideoThumbnail videoUrl={talents.selfieLetter} />
            )}
          </div>
        </div>
      ) : (
        <div className="boxNull">
          <div className="titleText">Selfie Letter não enviado</div>
          <div className="subText">
            Esse Talento ainda não atualizou suas informações de Selfie Letter.
            Confira novamente mais tarde.
          </div>
        </div>
      )}
    </>
  );
};

export default SelfieLetter;
