import React from "react";

import "./FitCultural.css";

const FitCultural = ({ culturalFitResult }) => {
  // Destructure data
  const { a, i, c, e } = culturalFitResult;

  // Calculate total to normalize values
  const total = a + i + c + e || 1; // Prevent division by zero

  // Calculate percentages (using placeholder values if all zeros)
  const aPercent = total === 1 && a === 0 ? 25 : (a / total) * 100;
  const iPercent = total === 1 && i === 0 ? 25 : (i / total) * 100;
  const cPercent = total === 1 && c === 0 ? 25 : (c / total) * 100;
  const ePercent = total === 1 && e === 0 ? 25 : (e / total) * 100;

  // Calculate the stroke-dasharray values for each segment
  // The circumference of the circle is 2πr where r is the radius
  const radius = 120;
  const circumference = 2 * Math.PI * radius;

  const aDash = (aPercent / 100) * circumference;
  const iDash = (iPercent / 100) * circumference;
  const cDash = (cPercent / 100) * circumference;
  const eDash = (ePercent / 100) * circumference;

  // Calculate stroke dashoffsets
  const aOffset = 0;
  const cOffset = aDash;
  const iOffset = aDash + cDash;
  const eOffset = aDash + cDash + iDash;

  return (
    <div className="fit-cultural-container">
      <div className="titleFitCulutal">Fit Cultural</div>
      <div className="chart-container">
        <svg width="200" height="200" viewBox="0 0 400 400">
          {/* Analítico segment - Light teal */}
          <circle
            cx="200"
            cy="200"
            r={radius}
            fill="none"
            stroke="#6DDCCB"
            strokeWidth="60"
            strokeDasharray={`${aDash} ${circumference - aDash}`}
            strokeDashoffset={aOffset}
            transform="rotate(-90, 200, 200)"
          />

          {/* Comunicador segment - Medium teal */}
          <circle
            cx="200"
            cy="200"
            r={radius}
            fill="none"
            stroke="#96E6DB"
            strokeWidth="60"
            strokeDasharray={`${cDash} ${circumference - cDash}`}
            strokeDashoffset={-cOffset}
            transform="rotate(-90, 200, 200)"
          />

          {/* Idealizador segment - Medium-light teal */}
          <circle
            cx="200"
            cy="200"
            r={radius}
            fill="none"
            stroke="#E0F7F5"
            strokeWidth="60"
            strokeDasharray={`${iDash} ${circumference - iDash}`}
            strokeDashoffset={-iOffset}
            transform="rotate(-90, 200, 200)"
          />

          {/* Executor segment - Light-medium teal */}
          <circle
            cx="200"
            cy="200"
            r={radius}
            fill="none"
            stroke="#0ECFB6"
            strokeWidth="60"
            strokeDasharray={`${eDash} ${circumference - eDash}`}
            strokeDashoffset={-eOffset}
            transform="rotate(-90, 200, 200)"
          />

          {/* Center white circle */}
          <circle cx="200" cy="200" r="90" fill="white" />
        </svg>

        {/* Labels */}
        <div className="label idealizador">
          <div className="label-content">Idealizador</div>
        </div>

        <div className="label comunicador">
          <div className="label-content">Comunicador</div>
        </div>

        <div className="label analitico">
          <div className="label-content">Analítico</div>
        </div>

        <div className="label executor">
          <div className="label-content">Executor</div>
        </div>
      </div>
    </div>
  );
};

export default FitCultural;
