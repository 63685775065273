import { useEffect, useState } from "react";
// import { AuthContext } from "../../../../providers/AuthProvider";
import api from "../../../../utils/api";
import { useNavigate } from "react-router-dom";
import checkIcon from "../../../../assets/Frame 20 (8).png";
// import iconBook from "../../../../assets/book-open.png";

import "./Cursos.css";
import LoadingIcon from "../../../../components/LoadingIcon";
import { generateSlug } from "../../../../utils/slug";

export const Cursos = ({ loading, setLoading, listCourses, setCourses }) => {
  const navigate = useNavigate();
  // const { user } = useContext(AuthContext);

  const getCourses = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/talents/courses/list`);
      setCourses(
        data?.data?.map((item) => ({
          id: item.id,
          ...item.attributes,
        })) || []
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  const handleNavigate = (item) => {
    const slug = generateSlug(item.title)
    navigate(`/curso/${slug}/${item.id}`)
  };

  const listInstructor = (data) => {
    const instructors = data
      ?.filter((i) => i.instructorType === "instructor")
      .map((item) => item.name);

    return instructors?.length > 0 ? instructors.join(", ") + "." : false;
  };

  const listGuest = (data) => {
    const instructors = data
      ?.filter((i) => i.instructorType === "guest")
      .map((item) => item.name);

    return instructors?.length > 0 ? instructors.join(", ") + "." : false;
  };

  const listHost = (data) => {
    const instructors = data
      ?.filter((i) => i.instructorType === "host")
      .map((item) => item.name);

    return instructors?.length > 0 ? instructors.join(", ") + "." : false;
  };

  const truncateText = (name, maxLength = 7) => {
    if (!name) return "...";
    let truncatedName = name;

    if (name?.length > maxLength) {
      truncatedName = name.slice(0, maxLength - 3) + "...";
    }

    return truncatedName;
  };

  const checkView = async (item) => {
    try {
      await api.post(`/talents/courses/${item.id}/access`);
    } catch (error) {
      console.log(error);
    } finally {
      handleNavigate(item);
    }
  };

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // height: "150px",
              width: "250px",
              marginTop: "50px",
            }}
          >
            <LoadingIcon />
          </div>
        </div>
      ) : listCourses.length > 0 ? (
        <div className="containerCursos">
          {listCourses.map((item, idx) => (
            <div
              className={`card ${item?.completed && "card_checked"}`}
              key={idx}
              onClick={() => checkView(item)}
            >
              <div
                className={`thumbnail ${
                  item?.completed && "thumbnail_checked"
                }`}
              >
                <img src={checkIcon} alt="" className="iconPLay" />
                {item?.thumbnail && (
                  <img src={item?.thumbnail} alt="" className="thumb" />
                )}
              </div>
              <div className="containerText">
                <div className="title">{item.title}</div>
                {/* <div>
                {listHost(item.instructors) && (
                    <div className="subtitle">
                      <span>{`Hosts: ${truncateText(
                        listHost(item.instructors)
                      )}`}</span>
                    </div>
                  )}

                  {listInstructor(item.instructors) && (
                    <div className="subtitle">
                      <span>{`Professores: ${truncateText(
                        listInstructor(item.instructors)
                      )}`}</span>
                    </div>
                  )}

                  {listGuest(item.instructors) && (
                    <div className="subtitle">
                      <span>{`Convidados: ${truncateText(
                        listGuest(item.instructors)
                      )}`}</span>
                    </div>
                  )}
                </div> */}
              </div>
              {!item?.accessed && <div className="iconNotifications"></div>}
            </div>
          ))}
        </div>
      ) : (
        <div className="alertNotVideos">
          Nenhum conteúdo disponível no momento. Fique ligado, em breve teremos
          novidades para você!
        </div>
      )}
    </>
  );
};
