import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";

import { MainSidebarCompanies } from "../MainSidebarCompanies/MainSidebarCompanies";
import { MainTopbarCompanies } from "../MainTopBarCompanies/MainTopBarCompanies";
import { ContentContainer } from "./ContentContainer";
import api from "../../utils/api";
import { toast } from "react-toastify";
import { AuthContext } from "../../providers/AuthProvider";
import mockNotifications from "../../utils/examples/notifications.json";
import { Toolbar } from "@mui/material";
import iconHeart from "../../assets/Vector (23).png";

import "./AuthorizedLayoutCompanies.css";
import { useLocation } from "react-router-dom";
import ChatComponent from "../ChatComponent";

export const AuthorizedLayoutCompanies = ({ children, styles }) => {
  const { pathname } = useLocation();
  const [mainSidebarVisible, setMainSidebarVisible] = useState(false);
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [supportModal, setSupportModal] = useState(false);

  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState({});

  const { user } = useContext(AuthContext);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/talents/notifications");
      // const { data } = mockNotifications;

      if (data) {
        setNotifications(
          data
            ?.map((item) => {
              return {
                notificationId: item?.id,
                ...item?.attributes,
              };
            })
            .sort((a, b) => {
              return b.id - a.id;
            })
        );
      }
      return [];
    } catch (error) {
      console.log(error);
      // toast.error("Não foi possível carregar a lista de notificações.");
    } finally {
      setLoading(false);
    }
  };

  function isMobileDevice() {
    return window.innerWidth <= 800;
  }

  let width = isMobileDevice() ? 10 : 80;

  return (
    <div className="containerBoxCompanies">
      <ChatComponent />
      
      <div className="containerScaleCompanies">
        <MainSidebarCompanies
          visible={mainSidebarVisible}
          setVisible={setMainSidebarVisible}
          supportModal={supportModal}
          setSupportModal={setSupportModal}
        />

        <MainTopbarCompanies
          mainSidebarVisible={mainSidebarVisible}
          setMainSidebarVisible={setMainSidebarVisible}
          notificationsVisible={notificationsVisible}
          setNotificationsVisible={setNotificationsVisible}
          loading={loading}
          notifications={notifications}
        />

        <ContentContainer
          styles={styles}
          notificationsVisible={notificationsVisible}
          setNotificationsVisible={setNotificationsVisible}
          loading={loading}
          notifications={notifications}
          setNotifications={setNotifications}
        >
          {children}
        </ContentContainer>

        <div className="footerCOmpanies">
          Desenvolvido com
          <img src={iconHeart} alt="" />
          <strong>© eComLovers®</strong>
        </div>
      </div>
    </div>
  );
};
