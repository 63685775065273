import React from "react";
import { SidebarLink } from "./styles";
import painel from "../../assets/iconsMenu/Artboard 3 copy 31 (1).png";
import lupa from "../../assets/iconsMenu/Artboard 3 copy 30.png";
import userIcom from "../../assets/iconsMenu/Artboard 3 copy 8 (2).png";
import iconBook from "../../assets/iconsMenu/Artboard 3 copy 25.png";
import iconPhone from "../../assets/iconsMenu/Artboard 3 copy 4.png";

import "./listItems.css";
import { useLocation } from "react-router-dom";

export const MainListItems = () => {
  const { pathname } = useLocation();

  return (
    <div className="containerMenuCompanies">
      <SidebarLink to={"/companies/painel"}>
        <div
          className={
            pathname === "/companies/painel" ? "itemLinkSelected" : "itemLink"
          }
        >
          <img src={painel} alt="icon" />
        </div>
      </SidebarLink>

      <SidebarLink to={"/companies/talentos"}>
        <div
          className={
            pathname === "/companies/talentos" ? "itemLinkSelected" : "itemLink"
          }
        >
          <img src={lupa} alt="icon" />
        </div>
      </SidebarLink>

      <SidebarLink to={"/companies/users"}>
        <div
          className={
            pathname === "/companies/users" ? "itemLinkSelected" : "itemLink"
          }
        >
          <img src={userIcom} alt="icon" />
        </div>
      </SidebarLink>

      <SidebarLink to={"/companies/finance"}>
        <div
          className={
            pathname === "/companies/finance" ? "itemLinkSelected" : "itemLink"
          }
        >
          <img src={iconBook} alt="icon" />
        </div>
      </SidebarLink>

      <SidebarLink to={"/companies/finance"}>
        <div
          className={
            pathname === "/companies/finance" ? "itemLinkSelected" : "itemLink"
          }
        >
          <img src={iconPhone} alt="icon" />
        </div>
      </SidebarLink>
    </div>
  );
};
