import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import hexToRgba from "../../../utils/hexToRgba";
import iconExempo from "../../../assets/Group 19.png";

import "./ExpandableList.css";

const ExpandableList = ({ filters, handleFilterChange }) => {
  const handleRatingChange = (newValue, category, id) => {
    const updatedSkills = filters.skills.map((group) => {
      if (group[category]) {
        return {
          [category]: group[category].map((skill) =>
            skill.id === id ? { ...skill, level: newValue } : skill
          ),
        };
      }
      return group;
    });

    handleFilterChange("skills", updatedSkills);
  };

  const handleCheckboxChange = (category, id) => {
    const updatedSkills = filters.skills.map((group) => {
      if (group[category]) {
        return {
          [category]: group[category].map((skill) =>
            skill.id === id ? { ...skill, checked: !skill.checked } : skill
          ),
        };
      }
      return group;
    });

    handleFilterChange("skills", updatedSkills);
  };

  const sliderHandleColor = (value) => {
    switch (value) {
      case 0:
        return "black";
      case 1:
        return "#EC5569";
      case 2:
        return "#ECA355";
      case 3:
        return "#009ABC";
      case 4:
        return "#57BAB8";
      default:
        return null;
    }
  };

  const handleValueLabelFormat = (value) => {
    switch (value) {
      case 0:
        return "Não conheço";
      case 1:
        return "Sei o básico";
      case 2:
        return "Estou aprendendo";
      case 3:
        return "Me viro bem";
      case 4:
        return "Sou expert";
      default:
        return null;
    }
  };

  const labelCase = {
    operation: "Operação",
    marketing: "Marketing e Vendas",
    marketplace: "Marketplaces",
    platform: "Plataformas",
    erp: "ERPs",
    designAndMedia: "Design e Mídia",
    dataAndBi: "Dados & BI",
    socialMedia: "Social Media",
    ia: "Inteligência artificial",
    dayToDay: "DIA A DIA",
  };

  const [visibleCount, setVisibleCount] = useState(3); // Começa mostrando 5 itens
  const [showAll, setShowAll] = useState(false);

  const visibleItems = showAll
    ? filters.skills
    : filters.skills?.slice(0, visibleCount);

  const handleShowMore = () => {
    setVisibleCount(visibleCount + 3); // Adiciona mais 5 itens a cada clique
    if (visibleCount >= filters.skills?.length) {
      setShowAll(true); // Se todos os itens estiverem visíveis, muda para o modo "Ver Menos"
    }
  };

  const handleShowLess = () => {
    setVisibleCount(3); // Volta a mostrar apenas os 5 primeiros
    setShowAll(false);
  };

  return (
    <div className="containerCardsSLides">
      {filters?.skills?.map((group, index) => {
        const category = Object.keys(group)[0]; // Obtém o nome da categoria (marketing, projects, etc.)
        const skills = group[category]; // Obtém os itens da categoria

        return (
          <Accordion
            key={index}
            sx={{
              border: "none",
              boxShadow: "none",
              backgroundColor: "transparent",
              "&:before": {
                display: "none",
              },
              borderBottom: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ margin: "0px", padding: "0px" }}
            >
              <div className="textSLideHeader">{labelCase[category]}</div>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                margin: "0px",
                padding: "0px",
                gap: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {skills
                ?.slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item) => (
                  <div
                    key={item.id}
                    className={`card ${item.checked && "checked"}`}
                  >
                    <label className="check-label">
                      <input
                        type="checkbox"
                        checked={item.checked}
                        onChange={() => handleCheckboxChange(category, item.id)}
                      />
                    </label>

                    <div className="conatinerLogoFilters">
                      <img src={item?.logo} alt="" style={{ height: "24px" }} />
                    </div>

                    <div className="conteinerNivel" style={{ width: "100%" }}>
                      <div className="text">{item.name}</div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Slider
                          style={{
                            margin: "0px",
                            width: "100%",
                            padding: "0px",
                          }}
                          sx={{
                            "& .MuiSlider-thumb:hover": {
                              boxShadow: `0px 0px 0px 8px ${hexToRgba(
                                sliderHandleColor(item.level),
                                0.16
                              )}`,
                            },
                            "& .MuiSlider-thumb.Mui-active": {
                              boxShadow: `0px 0px 0px 14px ${hexToRgba(
                                sliderHandleColor(item.level),
                                0.16
                              )}`,
                            },
                            "& .MuiSlider-thumb": {
                              width: 20, // Adjust thumb size
                              height: 20, // Adjust thumb size
                              marginLeft: "-8px",
                            },
                            "& .MuiSlider-track": {
                              height: 10, // Ensure track height matches your design
                            },
                          }}
                          slotProps={{
                            track: {
                              style: {
                                backgroundColor: sliderHandleColor(item.level),
                                // borderColor: sliderHandleColor(item.level),
                                border: "0.95px solid #ABABAB",
                                height: "10px",
                              },
                            },
                            thumb: {
                              style: {
                                backgroundColor: "#575757",
                                marginLeft:
                                  item.level === 0 || item.level === undefined
                                    ? "8px"
                                    : null,
                                border: "0.95px solid #ffff",
                                ":hover": {
                                  color: sliderHandleColor(item.level),
                                },
                              },
                            },
                            rail: {
                              style: {
                                backgroundColor: "#FFFFFF",
                                height: "10px",
                                border: "0.95px solid #ABABAB",
                                width: "100%",
                              },
                            },
                          }}
                          min={0}
                          defaultValue={0}
                          value={item.level}
                          onChange={(e) => {
                            handleRatingChange(
                              e.target.value,
                              category,
                              item.id
                            );
                          }}
                          max={4}
                          valueLabelFormat={handleValueLabelFormat}
                          valueLabelDisplay="auto"
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </AccordionDetails>
          </Accordion>
        );
      })}

      {/* {filters?.knowledge?.length > visibleCount && !showAll && (
        <button className="load-more-button" onClick={handleShowMore}>
          Ver Mais
        </button>
      )}

      {showAll && (
        <button className="load-more-button" onClick={handleShowLess}>
          Ver Menos
        </button>
      )} */}
    </div>
  );
};

export default ExpandableList;
