import React, { useEffect, useRef, useState } from "react";
import api from "../../utils/api";
import "./CardChat.css";

import iconSend from "../../assets/icone-enviar.png";
import iconClose from "../../assets/Vector (32).png";
import moment from "moment/moment";

const CardChat = ({ infoTalents }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [activeChat, setActiveChat] = useState(null);
  const [menssages, setMenssages] = useState([]);
  const [idMensagem, setIdMensagem] = useState(null);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");

  const fetchMessages = async () => {
    if (!isFetching) {
      setIsFetching(true);

      try {
        const { data } = await api.get(
          `/companies/messages/${infoTalents?.latestMessageId || idMensagem}/`
        );

        setMenssages(
          data?.data.sort(function (a, b) {
            if (a.attributes.createdAt < b.attributes.createdAt) return -1;
            if (a.attributes.createdAt > b.attributes.createdAt) return 1;
            return 0;
          })
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    }
  };

  const createMessagens = async () => {
    if (!isFetching) {
      setIsFetching(true);

      try {
        const { data } = await api.post(`/companies/messages/`, {
          data: {
            type: "messages",
            attributes: {
              receiver_id: infoTalents?.id,
              receiver_type: "Talent",
              text_msg:
                "Olá, estamos interessados no seu perfil! Podemos conversar?",
            },
          },
        });
        setData(data?.data);
        setIdMensagem(data?.data?.id);
        fetchMessages();
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    }
  };

  useEffect(() => {
    if (!infoTalents?.latestMessageId) {
      createMessagens();
    }
  }, []);

  useEffect(() => {
    let intervalId = null;
    intervalId = setInterval(fetchMessages, 10000);
    return () => clearInterval(intervalId);
  }, [isFetching]);

  const sendMessage = async () => {
    try {
      setMessage("");
      await api.post(`/companies/messages/`, {
        type: "messages",
        data: {
          attributes: {
            receiver_id: infoTalents?.id,
            receiver_type: "Talent",
            text_msg: message,
          },
        },
      });
    } catch (error) {
      console.log(error);
      alert("Something unexpeted happened.", "Please try again.");
    }
  };

  const closeChat = () => {
    setActiveChat(null);
  };

  const openChat = (contactId) => {
    setActiveChat(contactId);
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [menssages]);

  const ref = useRef(null);

  const handleClickFora = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      console.log("Clicou fora do componente!");
      closeChat();
    }
  };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickFora);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickFora);
  //   };
  // }, []);

  function getFirstName(fullName) {
    return fullName?.trim()?.split(" ")[0];
  }

  return (
    <>
      {/* <div
        key={data.id}
        className={`contact-item ${!data?.attributes?.readed ? "active" : ""}`}
        onClick={() => openChat(data.id)}
      >
        <div className="containerIconProfile">
          <img
            src={data?.attributes?.receiver?.avatar}
            alt={""}
            className="avatar"
          />
        </div>
        <div className="contact-info">
          <div className="contact-name">{data?.attributes?.receiver?.name}</div>
          <div className="contact-status">{data?.attributes?.textMsg}</div>
          <div className="contact-time">
            {moment(data?.attributes?.createdAt).format("HH:mm")}
          </div>
        </div>
      </div> */}

      <button
        className="buttonChatTalents"
        onClick={() => openChat(infoTalents?.id)}
      >{`Iniciar chat com ${getFirstName(infoTalents?.name)}`}</button>

      {activeChat && (
        <div className="chat-window-all" ref={ref}>
          {/* Chat Header */}
          <div className="chat-header">
            <div className="user-info">
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <div className="containerIconProfile">
                  <div
                  // className={`${
                  //   getActiveContact().id === 0 ? "statusON" : "statusOff"
                  // }`}
                  ></div>
                  <img src={infoTalents?.avatar} alt={""} className="avatar" />
                </div>
                <span className="user-name">{infoTalents?.name}</span>
              </div>
            </div>
            <button className="close-button" onClick={closeChat}>
              <img src={iconClose} alt="" className="iconClose" />
            </button>
          </div>

          {/* Chat Messages */}
          <div className="chat-messages">
            {menssages?.map((msg) => (
              <div
                key={msg?.id}
                className={`message ${
                  msg?.attributes?.sender?.type === "Company"
                    ? "user-message"
                    : "contact-message"
                }`}
              >
                <div className="message-content">
                  <div className="message-text">{msg?.attributes?.textMsg}</div>
                  <div className="message-time">
                    {moment(msg?.attributes?.createdAt).format("HH:mm")}
                  </div>
                  <div ref={messagesEndRef} />
                </div>
              </div>
            ))}
          </div>

          {/* Chat Input */}
          <div className="chat-input">
            <input
              type="text"
              placeholder="Mensagem"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
            <button className="send-button" onClick={sendMessage}>
              <img src={iconSend} alt="Enviar" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CardChat;
