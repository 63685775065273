import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { GridMenuIcon } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";
import notificationIcon from "../../assets/notification.svg";
import logo from "../../assets/ecomlovers-logo-transparente.svg";
import logoPerfil from "../../assets/Artboard 3 copy 8.png";
import iconSino from "../../assets/Artboard 3 copy 21.png";
import iconBar from "../../assets/Group 1432.png";
import { Notifications } from "@mui/icons-material";
import useIsMobileDevice from "../../utils/mobileCheck";
import api from "../../utils/api";

import "./MainTopbarCompanies.css";

export const MainTopbarCompanies = ({
  mainSidebarVisible,
  setMainSidebarVisible,
  notificationsVisible,
  setNotificationsVisible,
  loading,
  notifications,
}) => {
  const { signOut, setIsLoading, updateUser } = useContext(AuthContext);
  const { id, hash } = useParams();
  const { user } = useContext(AuthContext);
  const isMobile = useIsMobileDevice();
  const [avatarSrc, setAvatarSrc] = useState(user?.avatar || logoPerfil);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const notificationsRead =
    notifications?.length > 0 &&
    notifications?.reduce(
      (acc, item) => (item.readed === false ? acc + 1 : acc),
      0
    );

  return (
    <div className={`topBarCompanies`}>
      <div>
        <div
          sx={{
            opacity: 1,
            "&:hover": {
              opacity: 0.8,
            },
          }}
          disableFocusRipple
          disableTouchRipple
          onClick={() => {
            navigate("/companies/painel");
            setIsLoading(false);
          }}
          onTouchEnd={() => {
            navigate("/companies/painel");
            setIsLoading(false);
          }}
        >
          <img
            alt="eComLovers logo"
            src={logo}
            style={{ width: "121", height: "50px" }}
          />
        </div>
      </div>

      <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
        <div>
          <div
            sx={{
              opacity: 1,
              "&:hover": {
                opacity: 0.8,
              },
            }}
            style={{ padding: 0 }}
            onClick={() => {
              navigate("/settings");
              setIsLoading(false);
            }}
            onTouchEnd={() => {
              navigate("/settings");
              setIsLoading(false);
            }}
          >
            <img
              src={avatarSrc}
              alt=""
              className="iconToBarPerfil"
              style={{
                width: "42px",
                height: "41px",
                borderRadius: "50%",
                objectFit: "cover",
                overflow: "hidden",
                border: user.avatar && "1px solid #f7f7f7",
              }}
              // onError={handleImageError}
            />
          </div>
        </div>

        <div
          color="inherit"
          onClick={() => setNotificationsVisible(!notificationsVisible)}
          edge="start"
          style={{ color: "#211E1F" }}
        >
          {notificationsRead > 0 ? (
            <Typography
              color={"white"}
              fontSize={12}
              fontWeight={700}
              textAlign={"center"}
              style={{
                display: "flex",
                position: "absolute",
                border: "1px solid #EC5569",
                backgroundColor: "#EC5569",
                width: "5px",
                height: "5px",
                marginRight: "-30px",
                borderRadius: "50%",
                marginTop: -25,

                justifyContent: "center",
                alignItems: "center",

                padding: 2,
              }}
            ></Typography>
          ) : null}

          <img
            src={iconSino}
            // style={{ height: "35px" }}
            alt="notifications bell"
          />
        </div>
      </div>
    </div>
  );
};
