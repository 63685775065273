import styled from "@emotion/styled";
import { ListItem, ListItemIcon } from "@mui/material";
import { Link } from "react-router-dom";

export const SidebarLink = styled(Link)`
  color: #211e1f;
  text-decoration: none;
`;

export const SidebarItem = styled(ListItem)`
  display: flex;
  flex-direction: column;
  width: 150px;
  margin: 10px;
`;

export const SiderbarIcon = styled(ListItemIcon)`
  display: flex;
  justify-content: center;
`;

export const SiderbarIconImage = styled.img`
  width: 40px;
`;