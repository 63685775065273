import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import iconSend from "../../assets/icone-enviar.png";
import iconBack from "../../assets/Tipode material (1).png";

import "./Chat.css";
import api from "../../utils/api";

const Chat = () => {
  const navigate = useNavigate();
  const { id, companie } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [menssages, setMenssages] = useState([]);
  const [message, setMessage] = useState("");

  const fetchMessages = async () => {
    if (!isFetching) {
      setIsFetching(true);

      try {
        const { data } = await api.get(`/talents/messages/${id}/`);

        setMenssages(
          data?.data.sort(function (a, b) {
            if (a.attributes.createdAt < b.attributes.createdAt) return -1;
            if (a.attributes.createdAt > b.attributes.createdAt) return 1;
            return 0;
          })
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    }
  };

   useEffect(() => {
      fetchMessages();
    }, []);

  useEffect(() => {
    let intervalId = null;

    intervalId = setInterval(fetchMessages, 5000);
    return () => clearInterval(intervalId);
  }, [isFetching]);

  const sendMessage = async () => {
    try {
      setMessage("");
      await api.post(`/talents/messages/`, {
        type: "messages",
        data: {
          attributes: {
            receiver_id: menssages[0]?.attributes?.sender?.id,
            receiver_type: "Company",
            text_msg: message,
          },
        },
      });
    } catch (error) {
      console.log(error);
      alert("Something unexpeted happened.", "Please try again.");
    }
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [menssages]);

  return (
    <AuthorizedLayout>
      <div className="chat-container-inbox">
        <div className="messagesInbox">
          <div
            className="headerBackINbox"
            onClick={() => {
              navigate("/inbox");
            }}
          >
            <img src={iconBack} alt="Enviar" />{" "}
            <strong style={{ color: "#211E1F" }}>{companie}</strong>
          </div>
          {menssages?.map((msg, index) => (
            <div
              key={index}
              className={`messageInbox ${
                msg?.attributes?.sender?.type === "Company" ? "bot" : "user"
              }`}
            >
              {msg?.attributes?.textMsg}
            </div>
          ))}
           <div ref={messagesEndRef} />
        </div>
        <div className="input-containers">
          <input
            type="text"
            placeholder="Mensagem"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          />
          <button onClick={sendMessage}>
            <img src={iconSend} alt="Enviar" />
          </button>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default Chat;
