import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./TalentCard.css";
import { Avatar, IconButton } from "@mui/material";
import iconMoney from "../../assets/fi_10349139.png";
import iconCLose from "../../assets/Group (3).png";
import iconCheck from "../../assets/IconCheck.png";
import heart from "../../assets/heartIcon.svg";
import heartInactive from "../../assets/heartIconInactive.svg";
import api from "../../utils/api";
import { toast } from "react-toastify";

export const TalentCard = ({ talent, onUpdateFavorites }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState(talent.favorited);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const handleFavorite = async (id) => {
    try {
      await api.post(`/companies/favorites`, {
        data: {
          attributes: {
            talentId: id,
          },
        },
      });
    } catch (error) {
      toast.error(`Ocorreu um erro ao favoritar o talento. ${error}`)
    }
  };

  const handleDellFavorite = async (id) => {
    try {
      await api.delete(`/companies/favorites/${id}`);

      if (onUpdateFavorites) {
        onUpdateFavorites();
      }
    } catch (error) {
      toast.error(`Ocorreu um erro ao desfavoritar o talento. ${error}`)
    }
  };

  const toggleHeart = () => {
    const newValue = !isActive;
    setIsActive(newValue);

    if (newValue) {
      handleFavorite(talent?.id);
    } else {
      handleDellFavorite(talent?.id);
    }
  };

  const SalaryInfo = ({ isNegotiable }) => {
    const icon = isNegotiable ? iconCheck : iconCLose;
    const text = isNegotiable ? "Valor negociável" : "Mínimo não negociável";
    const iconSize = isNegotiable
      ? { width: "12px", height: "8.25px" }
      : { width: "11.29px", height: "15.06px" };

    return (
      <>
        <div className="containerInfoBox">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "18px",
              height: "18px",
            }}
          >
            <img
              src={iconMoney}
              alt="  "
              style={{ width: "16.13px", height: "16.13px" }}
            />
          </div>
          <div className="text">De {formatCurrency(talent.salaryMin)} a {formatCurrency(talent.salaryMax)}</div>
        </div>
        <div className="containerInfoBox">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "18px",
              height: "18px",
            }}
          >
            <img src={icon} alt="" style={iconSize} />
          </div>
          <div className="text">{text}</div>
        </div>
      </>
    );
  };

  return (
    <div className="talent-card">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="headerPainelCard">
          {talent?.avatar ? (
            <img src={talent?.avatar} alt="" />
          ) : (
            <Avatar
              className="img"
              sx={{
                fontSize: "small",
                backgroundColor: "primary.main",
              }}
            >
              {talent?.name
                ? talent.name
                  .split(" ")
                  .map((n) => n[0])
                  .join("")
                  .toUpperCase()
                : "ND"}
            </Avatar>
          )}
          <div>
            <div className="talent-name">{talent?.name}</div>
            <div className="textSecondary">{`${talent?.city || ""} - ${talent?.state || ""
              }`}</div>
          </div>
        </div>

        <IconButton onClick={toggleHeart}>
          <img
            src={isActive ? heart : heartInactive}
            alt="heart"
            style={{ width: "14px", height: "11.99px" }}
          />
        </IconButton>
      </div>

      <div className="containerCulturalFit">
        <div className="cardProfile">
          <div className="text">
            Idealizador
          </div>
        </div>

        <div className="cardProfile">
          <div className="text">
            Comunicador
          </div>
        </div>
      </div>

      <div className="boxInfoTalent">
        <SalaryInfo
          isNegotiable={talent.salaryNegotiable}
          salaryMin={talent.salaryMin}
          salaryMax={talent.salaryMax}
        />
      </div>

      <div className="viewTalentButton">
        <button
          onClick={() => navigate(`/companies/talentos/perfil/${talent?.id}`, { state: { from: location.pathname } })}
        >
          Perfil do talento
        </button>
      </div>
    </div>
  );
};
