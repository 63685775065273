import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import hexToRgba from "../../../utils/hexToRgba";

import "./Skills.css";

const Skills = ({ talents }) => {
  const sliderHandleColor = (value) => {
    switch (value) {
      case 0:
        return "black";
      case 1:
        return "#EC5569";
      case 2:
        return "#ECA355";
      case 3:
        return "#009ABC";
      case 4:
        return "#57BAB8";
      default:
        return null;
    }
  };

  const handleValueLabelFormat = (value) => {
    switch (value) {
      case 0:
        return "Não conheço";
      case 1:
        return "Sei o básico";
      case 2:
        return "Estou aprendendo";
      case 3:
        return "Me viro bem";
      case 4:
        return "Sou expert";
      default:
        return null;
    }
  };

  const handleValue = (value) => {
    const knowledgeAreas = {
      dont_know: 0,
      beginner: 1,
      can_manage: 2,
      can_teach: 3,
      expert: 4,
    };

    return knowledgeAreas[value] || false;
  };

  const labelCase = {
    operation: "Operação",
    marketing: "Marketing e Vendas",
    marketplace: "Marketplaces",
    platform: "Plataformas",
    erp: "ERPs",
    designAndMedia: "Design e Mídia",
    dataAndBi: "Dados & BI",
    socialMedia: "Social Media",
    ia: "Inteligência artificial",
    dayToDay: "DIA A DIA",
  };

  const dataSKils = [
    { operation: talents?.operation },
    { marketing: talents?.marketing },
    { marketplace: talents?.marketplace },
    { platform: talents?.platform },
    { erp: talents?.erp },
    { designAndMedia: talents?.designAndMedia },
    { dataAndBi: talents?.dataAndBi },
    { socialMedia: talents?.socialMedia },
    { ia: talents?.ia },
    { dayToDay: talents?.dayToDay },
  ];

  const allFalse = (dataSKils) => {
    return Object.values(dataSKils).every((value) => value === false);
  };

  return (
    <div className="containerCardsSLides">
      {!allFalse(talents?.talentKnowledgeArea) ? (
        dataSKils
          ?.filter((group) => Object.values(group)[0] !== null)
          .map((group, index) => {
            const category = Object.keys(group)[0]; // Obtém o nome da categoria (marketing, projects, etc.)
            const skills = group[category]; // Obtém os itens da categoria

            return (
              <Accordion
                key={index}
                sx={{
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                  "&:before": {
                    display: "none",
                  },
                  borderBottom: "none",
                }}
                disableGutters
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ margin: "0px", padding: "0px" }}
                >
                  <div className="textSLideHeaderProfile">
                    {labelCase[category]}
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    margin: "0px",
                    padding: "0px",
                    gap: "20px",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  {skills?.map((item) => (
                    <div key={item.id} className={`cardSKillProfile`}>
                      <div className="conatinerLogoFilters">
                        <img
                          src={item?.logoUrl}
                          alt=""
                          style={{ height: "24px" }}
                        />
                      </div>

                      <div className="conteinerNivelProfile">
                        <div className="text">{item?.optionText}</div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Slider
                            style={{
                              margin: "0px",
                              width: "100%",
                              padding: "0px",
                            }}
                            sx={{
                              "& .MuiSlider-thumb:hover": {
                                boxShadow: `0px 0px 0px 8px ${hexToRgba(
                                  sliderHandleColor(handleValue(item.level)),
                                  0.16
                                )}`,
                              },
                              "& .MuiSlider-thumb.Mui-active": {
                                boxShadow: `0px 0px 0px 14px ${hexToRgba(
                                  sliderHandleColor(handleValue(item.level)),
                                  0.16
                                )}`,
                              },
                              "& .MuiSlider-thumb": {
                                width: 0, // Adjust thumb size
                                height: 0, // Adjust thumb size
                                marginLeft: "-8px",
                              },
                              "& .MuiSlider-track": {
                                height: 10, // Ensure track height matches your design
                              },
                            }}
                            slotProps={{
                              track: {
                                style: {
                                  backgroundColor: sliderHandleColor(
                                    handleValue(item.level)
                                  ),
                                  // borderColor: sliderHandleColor(handleValue(item.level)),
                                  border: "0.95px solid #ABABAB",
                                  height: "10px",
                                },
                              },
                              thumb: {
                                style: {
                                  backgroundColor: "#575757",
                                  marginLeft:
                                    handleValue(item.level) === 0 ||
                                    handleValue(item.level) === undefined
                                      ? "8px"
                                      : null,
                                  border: "0px solid #ffff",
                                  ":hover": {
                                    color: sliderHandleColor(
                                      handleValue(item.level)
                                    ),
                                  },
                                },
                              },
                              rail: {
                                style: {
                                  backgroundColor: "#FFFFFF",
                                  height: "10px",
                                  border: "0.95px solid #ABABAB",
                                  width: "100%",
                                },
                              },
                            }}
                            min={0}
                            defaultValue={0}
                            value={handleValue(item.level)}
                            max={4}
                            valueLabelFormat={handleValueLabelFormat}
                            valueLabelDisplay="auto"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            );
          })
      ) : (
        <div className="boxNull">
          <div className="titleText">Nenhuma habilidade preenchida</div>
          <div className="subText">
            Esse Talento ainda não atualizou suas informações de habilidades.
            Confira novamente mais tarde.
          </div>
        </div>
      )}
    </div>
  );
};

export default Skills;
