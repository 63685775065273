import { useEffect, useState } from "react";
import { AuthorizedLayout } from "../../../../components/AuthorizedLayout";
import { useNavigate, useParams } from "react-router-dom";
import iconBack from "../../../../assets/Tipode material.png";
import checkIcon from "../../../../assets/Frame 20 (8).png";
import iconHeart from "../../../../assets/Vector (23).png";
import api from "../../../../utils/api";

import "./DetailsCurso.css";
import LoadingIcon from "../../../../components/LoadingIcon";

export const DetailsCurso = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [listCourse, setCourse] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCourses = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/talents/course/${id}/details`);
      setCourse(
        {
          id: data?.data?.id,
          ...data?.data?.attributes,
        } || []
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCourses();
  }, [id]);

  const handleNavigate = () => {
    navigate(`/academy`);
  };

  const navigateAula = (idCourse, idAula) => {
    navigate(`/curso/${idCourse}/aula/${idAula}`);
  };

  const labelCase = (label) => {
    switch (label) {
      case "guest":
        return "Convidado";
      case "host":
        return "Host";
      default:
        return "Professor";
    }
  };

  // console.log(listCourse);
  

  return (
    <AuthorizedLayout>
      <>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // height: "150px",
                width: "250px",
                marginTop: "50px",
              }}
            >
              <LoadingIcon />
            </div>
          </div>
        ) : (
          <div className="containerDetailsCurso">
            <div className="containerDetails">
              <div className="containerHeader" onClick={handleNavigate}>
                <img src={iconBack} alt="" />
                <span>{listCourse?.title}</span>
              </div>

              <img src={listCourse?.thumbnail} alt="" className="thumbIMage" />

              <div className="title">{listCourse?.title}</div>

              <div className="subtitle">{listCourse?.summary}</div>

              {/* <div className="containerSubtitle">
                <img src={iconBook} alt="" />
                <span>{video.nivel}</span>
              </div> */}

              {listCourse?.lessons?.map((leason, idx) => (
                <div
                  className={`card ${leason.watched && "card_checked"}`}
                  onClick={() => navigateAula(listCourse.id, leason.id)}
                  key={idx}
                >
                  {leason.watched ? (
                    <img src={checkIcon} alt="" />
                  ) : (
                    <div className="circle"></div>
                  )}
                  <div className="title">{leason.title}</div>
                  {/* <span>{leason.tempo}</span> */}
                </div>
              ))}

              {listCourse?.instructors?.map((i, idx) => (
                <div className="containerTeacher" key={idx}>
                  <div className="headers">
                    <img src={i?.photoUrl} alt="" className="imageProf" />
                    <div className="containerText">
                      <span>{labelCase(i?.instructorType)}</span>
                      <div>{i?.name}</div>
                    </div>
                  </div>
                  <div className="description">{i?.description}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </>

      {!loading && (
        <div
          className={
            listCourse?.length <= 1 ? "footerMobileFixed" : "footerMobile"
          }
        >
          Desenvolvido com
          <img src={iconHeart} alt="" />
          <strong>© eComLovers®</strong>
        </div>
      )}
    </AuthorizedLayout>
  );
};
