import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import iconAddPhoto from "../../assets/Frame 18 (1).png";
import iconHeart from "../../assets/Vector (23).png";

import { AuthContext } from "../../providers/AuthProvider";
import astrounautStandingWave from "../../assets/posesPNG/astronout-standing-wave.png";
import editIcon from "../../assets/Group 1438.png";
import unmask from "../../utils/unmask";
import api from "../../utils/api";

import "./ClientProfile.css";
import ReactInputMask from "react-input-mask";
import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import { Autocomplete, LinearProgress, linearProgressClasses } from "@mui/material";
import axios from "axios";

const ClientProfile = () => {
  const location = useLocation();
  const screenUrl = location.state?.scree;
  const navigate = useNavigate();
  const { updateUser, user, signOut } = useContext(AuthContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const [tempPhone, setTempPhone] = useState("");
  const [scree, setScreen] = useState(screenUrl ? screenUrl : "screen1");
  const [brazilStates, setBrazilStates] = useState([]);
  const [cities, setCities] = useState([]);

  const checkList = {
    isCulturalFitFilled: user?.isCulturalFitFilled,
    isSelfieLetterFilled: user?.isSelfieLetterFilled,
    isTalentKnowledgeAreaFilled: user?.isTalentKnowledgeAreaFilled,
    isTalentKnowledgeSkillFilled: user?.isTalentKnowledgeSkillFilled,
    userAVatar: user?.avatar !== null,
  };

  const form = useFormik({
    initialValues: {
      name: user?.name || "",
      birthday: user?.birthdate
        ? moment.utc(user.birthdate).format("DD/MM/YYYY")
        : "",
      email: user?.email || "",
      phone: user?.phone || "",
      avatar: user?.avatar || null,
      state: user?.state || "",
      city: user?.city || "",
      linkedin: user?.linkedinUrl || "",
    },

    validationSchema: yup.object({
      linkedin: yup
        .string()
        .url("Deve ser uma URL válida") // Valida como uma URL válida
        .nullable(), // Permite que o campo seja vazio ou nulo
    }),

    onSubmit: (values) => updateSettings(values),
  });

  const resetForm = (values) => {
    form.setValues({
      name: values?.name || "",
      birthday: values?.birthdate
        ? moment.utc(values.birthdate).format("DD/MM/YYYY")
        : "",
      email: values?.email || "",
      phone: values?.phone || "",
      avatar: values?.avatar || null,
      state: values?.state || "",
      city: values?.city || "",
      linkedin: values?.linkedinUrl || "",
    });
  };



  const [avatarSrc, setAvatarSrc] = useState(user?.avatar || editIcon);

  const handleImageError = () => {
    setAvatarSrc(user?.avatar);
  };

  useEffect(() => {
    if (user.avatar) {
      handleImageError();
    }
  }, [user]);

  useEffect(() => {
    if (user.state && brazilStates.length > 0) {
      getCities(user.state);
    }
  }, [user.state, brazilStates]);

  const updateSettings = async (values) => {
    setSaveLoading(true);

    if (values?.avatar !== user?.avatar && !values.avatar) {
      try {
        const { data } = await api.put(`/talents/talents/${user.id}`, {
          data: {
            attributes: {
              avatar: { data: values?.avatar },
              name: values.name.trim(),
              phone: unmask(values.phone.trim()),
              birthdate: moment(values.birthday, "DD/MM/YYYY").toISOString(),
              email: values.email,
              state: values.state,
              city: values.city,
              linkedin_url: values.linkedin,
            },
          },
        });
        if (data) {
          updateUser(data);

          resetForm(data.data.attributes);
          toast.success("Dados da conta atualizados com sucesso!");
        }
      } catch (error) {
        toast.error("Ocorreu um erro ao tentar atualizar os dados da conta.");
      } finally {
        setSaveLoading(false);
      }
    } else {
      try {
        const { data } = await api.put(`/talents/talents/${user.id}`, {
          data: {
            attributes: {
              name: values.name.trim(),
              phone: values.phone,
              birthdate: moment(values.birthday, "DD/MM/YYYY").toISOString(),
              email: values.email,
              state: values.state,
              city: values.city,
              linkedin_url: values.linkedin,
            },
          },
        });
        if (data) {
          updateUser(data);
          resetForm(data.data.attributes);
          toast.success("Dados da conta atualizados com sucesso!");
        }
      } catch (error) {
        toast.error("Ocorreu um erro ao tentar atualizar os dados da conta.");
      } finally {
        setSaveLoading(false);
      }
    }
  };

  useEffect(() => {
    setTempPhone(form.values.phone);
  }, [form.values.phone]);

  const getBase64 = (file, maxWidth = 2000, maxHeight = 2000) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        const img = new Image();

        img.onload = function () {
          // Criar o canvas para redimensionar a imagem
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Calcular a nova largura e altura mantendo a proporção
          let width = img.width;
          let height = img.height;

          if (width > maxWidth || height > maxHeight) {
            const ratio = Math.min(maxWidth / width, maxHeight / height);
            width = width * ratio;
            height = height * ratio;
          }

          // Configurar o canvas com o novo tamanho
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height); // Desenha a imagem no canvas redimensionado

          // Converter o canvas para base64 (usando compressão de 0.7)
          const base64 = canvas.toDataURL("image/jpeg", 0.7); // Você pode ajustar a qualidade aqui

          resolve(base64); // Retorna a imagem redimensionada em base64
        };

        img.onerror = reject;
        img.src = e.target.result; // Atribui a imagem para ser carregada
      };

      reader.onerror = reject;
      reader.readAsDataURL(file); // Lê o arquivo como base64
    });
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];

    getBase64(file, 350, 350) // Reduzindo a imagem para 500x500 (ou o tamanho que preferir)
      .then((result) => {
        file["base64"] = result;
        form.setFieldValue(`avatar`, result); // Atualiza o campo do formulário
        setAvatarSrc(result); // Atualiza o estado da imagem

        const updateSettings = async () => {
          try {
            const { data } = await api.put(`/talents/talents/${user.id}`, {
              data: {
                attributes: {
                  avatar: { data: result },
                },
              },
            });

            if (data) {
              updateUser(data); // Atualiza o usuário
              resetForm(data.data.attributes); // Reseta o formulário
              toast.success("Foto do perfil atualizada com sucesso!");
            }
          } catch (error) {
            toast.error(
              "Ocorreu um erro ao tentar atualizar os dados da conta."
            );
          } finally {
            setSaveLoading(false);
          }
        };

        updateSettings();
      })
      .catch((error) => {
        if (error?.response?.status) {
          if (error.response.status !== 401) {
            if (
              error?.response?.data?.errors &&
              error?.response?.data?.errors[0]?.title
            ) {
              alert(`${error.response.data.errors[0].title}`);
            } else {
              alert("Something unexpected happened. Please try again.");
            }
          } else {
            alert("Session expired", "Please login in again.");
          }
        } else {
          alert("Something unexpected happened. Please try again.");
        }
      });
  };

  const contarTrues = (obj) => {
    let contador = 0;

    for (let key in obj) {
      if (obj[key] === true) {
        contador++;
      }
    }

    return contador;
  };

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const { data } = await axios.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
        );
        setBrazilStates(data);
      } catch (error) {
        console.error("Erro ao carregar estados:", error);
      }
    };

    fetchStates();
  }, []);

  const getCities = async (name) => {
    try {
      const stateFound = brazilStates.find(state => state.sigla === name)

      const data = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateFound.id}/municipios`
      );
      if (data) {
        setCities(data?.data);
      }
    } catch (error) { }
  };

  return (
    <AuthorizedLayout>
      <div
        className="containerProfileTalents"
        style={{ backgroundColor: "#2D2D2D" }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#2D2D2D",
          }}
        >
          {contarTrues(checkList) <= 4 && (
            <div className="containerHeaderProfile">
              <div className="cardCompleteProfile">
                <div className="cardLeft">
                  <div className="textProfile">Complete seu perfil</div>
                  <LinearProgress
                    sx={{
                      height: 10,
                      borderRadius: 5,
                      [`&.${linearProgressClasses.colorPrimary}`]: {
                        backgroundColor: "#BEFCF9",
                        border: "2px solid #BEFCF9",
                      },
                      [`& .${linearProgressClasses.bar}`]: {
                        borderRadius: "5px 0px 0px 5px",
                        backgroundColor: "#57BAB8",
                      },
                    }}
                    value={((contarTrues(checkList) / 5) * 100).toFixed(2)}
                    variant="determinate"
                  />

                  <div className="textProfileSUb">
                    Perfis completos e de qualidade aumentam em 4,5 vezes suas
                    chances de ser contratado por clientes.
                  </div>

                  <div
                    className="button"
                    onClick={() => navigate("/settings/status")}
                  >
                    Clique aqui
                  </div>
                </div>

                <div className="cardRigth">
                  <img
                    src={astrounautStandingWave}
                    alt="backgroud img"
                    style={{
                      position: "absolute",
                      width: "131px",
                      top: "-20px",
                      right: "0px",
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="containerContentProfile" style={{ flex: 1 }}>
            <div className="containerPhotoProfile">
              <label className="uploadIcon" htmlFor="fupload">
                <div style={{ position: "relative" }}>
                  {user.avatar && (
                    <img
                      src={iconAddPhoto}
                      alt="edit"
                      style={{
                        position: "absolute",
                        bottom: "-10px",
                        right: "5px",
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  )}
                  <img
                    src={avatarSrc}
                    alt="edit"
                    className={form.values.avatar ? "fotoIcon" : "editIcon"}
                    htmlFor="fupload"
                    onError={handleImageError}
                  />
                </div>

                <input
                  type="file"
                  id="fupload"
                  name="fupload"
                  className="fupload form-control"
                  accept=".jpeg, .png, .jpg"
                  onChange={handleFileInputChange}
                  style={{ display: "none" }}
                />
              </label>
            </div>

            <div
              className="containerInput"
              style={{ marginBottom: "20px", lineHeight: "5px" }}
            >
              <div className="text">Nome Completo</div>
              <input
                type="text"
                label="Nome completo"
                placeholder="Nome completo"
                className="inputLogin"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.name}
                name="name"
              />
              {form.touched.name && form.errors.name && (
                <div style={{ color: "red" }} className="text">
                  {form.errors.name}
                </div>
              )}
            </div>

            <div
              className="containerInput"
              style={{ marginBottom: "20px", lineHeight: "5px" }}
            >
              <div className="text">Data de nascimento</div>

              <ReactInputMask
                mask="99/99/9999"
                placeholder="Data de nascimento"
                value={form.values.birthday}
                onChange={(e) => form.setFieldValue(`birthday`, e.target.value)}
                onBlur={() => {
                  form.setFieldTouched(`birthday`, true);
                }}
                className="inputLogin"
              />
              {form.touched.birthday && form.errors.birthday && (
                <div style={{ color: "red" }} className="text">
                  {form.errors.birthday}
                </div>
              )}
            </div>

            <div
              className="containerInput"
              style={{ marginBottom: "20px", lineHeight: "5px" }}
            >
              <div className="text">Email</div>
              <input
                type="email"
                label="E-mail"
                placeholder="E-mail"
                className="inputLogin"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.email}
                name="email"
              />
              {form.touched.email && form.errors.email && (
                <div style={{ color: "red" }} className="text">
                  {form.errors.email}
                </div>
              )}
            </div>

            <div
              className="containerInput"
              style={{ marginBottom: "20px", lineHeight: "5px" }}
            >
              <div className="text">Telefone</div>
              {/* <ReactInputMask
                mask={
                  tempPhone.replace(/\s/g, "").length > 13
                    ? "+55 (99) 9 9999-9999"
                    : "+55 (99) 9999-99999"
                }
                placeholder="Telefone"
                value={form.values.phone}
                onChange={(e) => form.setFieldValue(`phone`, e.target.value)}
                onBlur={() => {
                  form.setFieldTouched(`phone`, true);
                }}
                className="inputLogin"
              /> */}
              <ReactInputMask
                              mask={"+55 (99) 9 9999-9999"}
                              type="tel" // Changed from "number" to "tel"
                              value={form.values.phone}
                              onChange={(e) => form.setFieldValue(`phone`, e.target.value)}
                              onBlur={() => {
                                form.setFieldTouched("phone", true);
                              }}
                              maskChar={""}

                            >
                              {() => (
                                <input
                                  className="inputLogin"
                                  aria-label="Digite aqui sua resposta"
                                  placeholder="Telefone"
                                  autoCapitalize="none"
                                  value={form.values.phone}
                                  inputMode="tel" // Changed to "tel" for phone numbers
                                />
                              )}
                            </ReactInputMask>
              {form.touched.phone && form.errors.phone && (
                <div style={{ color: "red" }} className="text">
                  {form.errors.phone}
                </div>
              )}
            </div>

            <div
              class="containerInput"
              style={{ marginBottom: "20px", lineHeight: "5px" }}
            >
              <select
                className="inputLogin"
                placeholder="Insira aqui sua resposta"
                value={form.values.state}
                onChange={(e) => {
                  form.setFieldValue("state", e.target.value);
                  getCities(e.target.value);
                }}
                onBlur={() => form.setFieldTouched("field", true)}
              >
                <option
                  className="inputListSelect"
                  value={''}
                >
                  Selecione seu estado
                </option>
                {brazilStates
                  .sort((a, b) => a.nome.localeCompare(b.nome)) // Ordena os estados pelo nome
                  .map((item, index) => (
                    <option
                      key={index}
                      value={item.sigla}
                      className="inputListSelect"
                    >
                      {item.nome}
                    </option>
                  ))}
              </select>
            </div>

            <div
              className="containerInput"
              style={{ display: "flex", alignItems: "center", marginBottom: "20px", lineHeight: "5px" }}
            >
              <Autocomplete
                style={{ width: "100%" }}
                options={cities || []}
                getOptionLabel={(option) => option.nome || ""}
                filterOptions={(options, { inputValue }) => {
                  // Função para limpar o texto removendo acentos, espaços e caracteres especiais
                  const cleanText = (str) => {
                    if (!str) return ""; // Retorna string vazia se for null ou undefined
                    return str
                      .toString() // Converte para string se não for
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "") // Remove acentos
                      .toLowerCase() // Converte para minúsculas
                      .replace(/[.,\-\s]/g, "") // Remove espaços, pontos, vírgulas e hífens
                      .trim();
                  };

                  // Valor de input limpo
                  const cleanInputValue = cleanText(inputValue);

                  return options.filter(
                    (option) =>
                      option &&
                      option.nome &&
                      cleanText(option.nome).includes(cleanInputValue)
                  );
                }}
                value={
                  cities?.find((city) => {
                    const cleanText = (str) => {
                      if (!str) return "";
                      return str
                        .toString()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLowerCase()
                        .replace(/[.,\-\s]/g, "")
                        .trim();
                    };

                    return city && city.nome
                      ? cleanText(city.nome) === cleanText(form.values.city || user?.city || "")
                      : false;
                  }) || null
                }
                onChange={(event, newValue) => {
                  const selectedValue =
                    newValue && newValue.nome ? newValue.nome.trim() : "";
                  form.setFieldValue("city", selectedValue);
                }}
                renderInput={(params) => (
                  <div
                    ref={params.InputProps.ref}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      {...params.inputProps}
                      placeholder="Cidade"
                      style={{ width: "100%" }}
                      className="inputLogin"
                    />
                  </div>
                )}
                PaperComponent={(props) => (
                  <div
                    {...props}
                    style={{
                      position: "fixed",
                      bottom: "50px",
                      zIndex: 10000,
                      width: "100%",
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} className="optionCustom">
                    {option.nome}
                  </li>
                )}
                noOptionsText={
                  <div style={{ backgroundColor: "#fff", padding: "10px" }}>
                    Cidade não encontrada
                  </div>
                }
              />
            </div>

            <div
              className="containerInput"
              style={{ marginBottom: "20px", lineHeight: "5px" }}
            >
              <div className="text">Linkedin</div>
              <input
                type="text"
                label="Linkedin"
                placeholder="https://linkedin.com/in/usuario"
                className="inputLogin"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.linkedin}
                name="linkedin"
              />
              {form.touched.linkedin && form.errors.linkedin && (
                <div style={{ color: "white" }} className="text">
                  {form.errors.linkedin}
                </div>
              )}
            </div>

            <div
              className={`buttonClientProfile ${saveLoading && "loading"}`}
              onClick={form.submitForm}
              style={{ width: "87vw", marginTop: "0px" }}
            >
              {saveLoading ? "Salvando" : "Salvar"}
            </div>

            <div
              onClick={() => signOut()}
              className="buttonSiginOut"
              style={{ marginTop: "20px" }}
            >
              Sair
            </div>
          </div>
        </div>
        <div className="footerPageMObileProfile">
          Desenvolvido com
          <img src={iconHeart} alt="" />
          <strong>© eComLovers®</strong>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default ClientProfile;
