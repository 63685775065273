import React from "react";
import { Rating } from "@mui/material";

import heart from "../../../assets/heartIcon.svg";
import heartInactive from "../../../assets/heartIconInactive.svg";

import "./Knowledge.css";

const Knowledge = ({ talents }) => {
  const handleValue = (value) => {
    const knowledgeAreas = {
      dont_know: 0,
      beginner: 1,
      can_manage: 2,
      can_teach: 3,
      expert: 4,
    };

    return knowledgeAreas[value] || false;
  };

  // console.log(talents);

  return (
    <>
      {talents?.knowledgeSkills?.length > 0 ? (
        <div className="boxKnowledge">
          <div className="gridKnowledge">
            {talents?.knowledgeSkills?.map((item) => (
              <div key={item.id} className="cardKnowledgeProfile">
                <div className="containerNivelProfile">
                  <div className="text">{item?.name}</div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Rating
                      defaultValue={handleValue(item.level)}
                      precision={1}
                      max={3}
                      readOnly
                      icon={
                        <img
                          src={heart}
                          alt="heart"
                          style={{ width: "23.52px", height: "20.14px" }}
                        />
                      }
                      emptyIcon={
                        <img
                          src={heartInactive}
                          alt="heart"
                          style={{ width: "23.52px", height: "20.14px" }}
                        />
                      }
                      sx={{
                        "& .MuiRating-icon": {
                          margin: "2px", // Espaço entre os ícones
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="boxNull">
          <div className="titleText">Nenhum conhecimento adicionado</div>
          <div className="subText">
            Esse Talento ainda não atualizou suas informações de conhecimentos.
            Confira novamente mais tarde.
          </div>
        </div>
      )}
    </>
  );
};

export default Knowledge;
