import { useContext, useEffect, useState } from "react";
import api from "../../../../utils/api";
import { useNavigate } from "react-router-dom";
import checkIcon from "../../../../assets/Frame 20 (8).png";

import "./Lives.css";
import LoadingIcon from "../../../../components/LoadingIcon";
import { generateSlug } from "../../../../utils/slug";

export const Lives = ({ loading, setLoading, listCourses, setCourses }) => {
  const navigate = useNavigate();

  const getCourses = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/talents/media/list`);
      setCourses(
        data?.data
          ?.filter((type) => type?.attributes?.mediaType === "live")
          .map((item) => ({
            id: item.id,
            ...item.attributes,
          })) || []
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  const handleNavigate = (item) => {
    const slug = generateSlug(item.title)
    navigate(`/live/${slug}/${item.id}`)
  };

  const listInstructor = (data) => {
    const instructors = data
      ?.filter((i) => i.instructorType === "instructor")
      .map((item) => item.name);

    return instructors?.length > 0 ? instructors.join(", ") + "." : false;
  };

  const listGuest = (data) => {
    const instructors = data
      ?.filter((i) => i.instructorType === "guest")
      .map((item) => item.name);

    return instructors?.length > 0 ? instructors.join(", ") + "." : false;
  };

  const truncateText = (name, maxLength = 7) => {
    if (!name) return "...";
    let truncatedName = name;

    if (name?.length > maxLength) {
      truncatedName = name.slice(0, maxLength - 3) + "...";
    }

    return truncatedName;
  };

  const checkView = async (item) => {
    try {
      await api.post(`/talents/media/${item.id}/access`);
    } catch (error) {
      console.log(error);
    } finally {
      handleNavigate(item);
    }
  };

  const getYouTubeVideoId = (url) => {
    try {
      const urlObj = new URL(url);
      if (urlObj.hostname === "youtu.be") {
        return urlObj.pathname.substring(1);
      }
      return urlObj.searchParams.get("v");
    } catch (error) {
      return null;
    }
  };

  const thumbnailUrl = (url) => {
    const videoId = getYouTubeVideoId(url);
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // height: "150px",
              width: "250px",
              marginTop: "50px",
            }}
          >
            <LoadingIcon />
          </div>
        </div>
      ) : listCourses.length > 0 ? (
        <div className="containerLives">
          {listCourses.map((item, idx) => (
            <div
              className={`card ${item?.watched && "card_checked"}`}
              key={idx}
              onClick={() => checkView(item)}
            >
              <div
                className={`thumbnail ${item?.watched && "thumbnail_checked"}`}
              >
                <img src={checkIcon} alt="" className="iconPLay" />
                {item?.thumbnail && (
                  <img src={item?.thumbnail} alt="" className="thumb" />
                )}
              </div>
              <div className="containerText">
                <div className="title">{truncateText(item.title, 35)}</div>
                {/* <div>
                  {listInstructor(item.instructors) && (
                    <div className="subtitle">
                      <span>{`Instrutor: ${truncateText(
                        listInstructor(item.instructors)
                      )}`}</span>
                    </div>
                  )}
                  {listGuest(item.instructors) && (
                    <div className="subtitle">
                      <span>{`Convidado: ${truncateText(
                        listGuest(item.instructors)
                      )}`}</span>
                    </div>
                  )}
                </div> */}
                {item?.aovivo && <div className="iconLive">AO VIVO</div>}
              </div>
              {!item?.accessed && <div className="iconNotifications"></div>}
            </div>
          ))}
        </div>
      ) : (
        <div className="alertNotVideos">
          Nenhum conteúdo disponível no momento. Fique ligado, em breve teremos
          novidades para você!
        </div>
      )}
    </>
  );
};
