import React, { useEffect, useState } from "react";
import "./ChatComponent.css";

import iconCompanie from "../../assets/Artboard 3 copy 6.png";
import DetailChat from "./DetailChat";
import api from "../../utils/api";

const ChatComponent = () => {
  const [isContactsListOpen, setIsContactsListOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [menssages, setMenssages] = useState([]);

  const fetchMessages = async () => {
    if (!isFetching) {
      setIsFetching(true);

      try {
        const { data } = await api.get(`/companies/messages/`);

        setMenssages(
          data?.data.sort(function (a, b) {
            if (a.attributes.createdAt > b.attributes.createdAt) return -1;
            if (a.attributes.createdAt < b.attributes.createdAt) return 1;
            return 0;
          })
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  useEffect(() => {
    let intervalId = null;

    intervalId = setInterval(fetchMessages, 10000);
    return () => clearInterval(intervalId);
  }, [isFetching]);

  const toggleContactsList = () => {
    setIsContactsListOpen(!isContactsListOpen);
  };

  return (
    <div className="chat-system-container">
      {/* Main Contacts List (collapsed or expanded) */}
      {isContactsListOpen ? (
        <div className="contacts-list-container">
          <div className="contacts-header">
            <div className="containerTitleHeader">
              <img src={iconCompanie} alt="" className="avatarCompanie" />
              <span>Mensagens</span>
            </div>
            <button className="dropdown-button" onClick={toggleContactsList}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="#000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div className="contacts-scroll">
            {menssages?.map((contact, idx) => (
              <DetailChat
                id={contact.id}
                nameTalents={contact?.attributes?.receiver?.name}
                data={contact}
              />
            ))}
          </div>
          <div style={{ height: "10px", width: "100%" }}></div>
        </div>
      ) : (
        <div className="contacts-collapsed" onClick={toggleContactsList}>
          <div className="contacts-collapsed-header">
            <div className="containerTitleHeader">
              <img src={iconCompanie} alt="" className="avatarCompanie" />
              <span>Mensagens</span>
            </div>
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 15L12 9L18 15"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      )}

      {/* Single Active Chat Window */}
    </div>
  );
};

export default ChatComponent;
