import { useEffect, useState } from "react";
import { AuthorizedLayout } from "../../../../components/AuthorizedLayout";
import { useNavigate, useParams } from "react-router-dom";
import YouTube from "react-youtube";
import api from "../../../../utils/api";
import iconBack from "../../../../assets/Tipode material.png";
import checkIcon from "../../../../assets/Frame 20 (8).png";
import iconHeart from "../../../../assets/Vector (23).png";
import arrowIcon from "../../../../assets/Vector.png";

import "./PlayVideo.css";
import LoadingIcon from "../../../../components/LoadingIcon";
import { generateSlug } from "../../../../utils/slug";

export const PlayVideo = () => {
  const navigate = useNavigate();
  const { cursoId, aulaId } = useParams();
  const [listCourse, setCourse] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCourses = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/talents/courses/${cursoId}/lessons/${aulaId}/details`
      );
      setCourse(
        {
          id: data?.data?.id,
          ...data?.data?.attributes,
        } || []
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCourses();
  }, [aulaId]);

  const checkView = async () => {
    try {
      await api.post(
        `/talents/courses/${listCourse?.course?.id}/lessons/${listCourse?.id}/mark_completed`
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigate = async () => {
    if (!listCourse.nextLesson) {
      await checkView();
    }

    const slug = generateSlug(listCourse?.course?.title)
    navigate(`/curso/${slug}/${listCourse?.course?.id}`);
  };

  const nextLeason = async () => {
    await checkView();

    navigate(
      `/curso/${listCourse?.course?.id}/aula/${listCourse?.nextLesson?.id}`
    );
  };

  const previousLesson = async () => {
    await checkView();

    navigate(
      `/curso/${listCourse?.course?.id}/aula/${listCourse?.previousLesson?.id}`
    );
  };

  const getYouTubeVideoId = (url) => {
    try {
      const urlObj = new URL(url);
      if (urlObj.hostname === "youtu.be") {
        return urlObj.pathname.substring(1);
      }
      return urlObj.searchParams.get("v");
    } catch (error) {
      return null;
    }
  };

  const videoId = getYouTubeVideoId(listCourse?.nextLesson?.link);

  const thumbnailUrl = videoId
    ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
    : null;

  const listInstructor = (data) => {
    const instructors = data
      ?.filter((i) => i.instructorType === "instructor")
      .map((item) => item.name);

    return instructors?.length > 0 ? instructors.join(", ") + "." : false;
  };

  const listGuest = (data) => {
    const instructors = data
      ?.filter((i) => i.instructorType === "guest")
      .map((item) => item.name);

    return instructors?.length > 0 ? instructors.join(", ") + "." : false;
  };

  const truncateText = (name, maxLength = 7) => {
    if (!name) return "...";
    let truncatedName = name;

    if (name?.length > maxLength) {
      truncatedName = name.slice(0, maxLength - 3) + "...";
    }

    return truncatedName;
  };

  const opts = {
    height: "197px",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      cc_load_policy: 0,
      controls: 2,
      fs: 1,
      iv_load_policy: 3,
      loop: 0,
      modestbranding: 1,
      origin: 0,
      playsinline: 0,
      rel: 0,
      showinfo: 0,
      start: 0,
    },
  };

  // console.log(listCourse);

  return (
    <AuthorizedLayout>
      <>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // height: "150px",
                width: "250px",
                marginTop: "50px",
              }}
            >
              <LoadingIcon />
            </div>
          </div>
        ) : (
          <div className="containerPlayVideo">
            <div className="containerDetails">
              <div className="containerHeader" onClick={handleNavigate}>
                <img src={iconBack} alt="" />
                <span>{listCourse?.course?.title}</span>
              </div>
              <div style={{ borderRadius: "12px", overflow: "hidden" }}>
                <YouTube videoId={videoId} opts={opts} />
              </div>
              <div className="containerTeacher">
                <div className="headers">
                  <div className="containerText2">
                    <div>{listCourse?.title}</div>

                    {listInstructor(listCourse.instructors) && (
                      <div className="text2">
                        Instrutor:{" "}
                        <span>{listInstructor(listCourse.instructors)}</span>
                      </div>
                    )}

                    {listGuest(listCourse.instructors) && (
                      <div className="text2">
                        convidado:{" "}
                        {listCourse.instructors && (
                          <span>{listGuest(listCourse.instructors)}</span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="description">{listCourse?.summary}</div>
              </div>

              <div className="containerButtons">
                <button
                  className="checkVideo"
                  style={{
                    background: "transparent",
                    border: "none",
                  }}
                  onClick={nextLeason}
                ></button>
                <button
                  className={`checkVideo ${listCourse?.watched && "checked"}`}
                  style={{
                    flex: !listCourse.nextLesson && "none",
                    padding: "0px 8px",
                  }}
                  onClick={async () => {
                    await checkView();
                    await getCourses();
                  }}
                >
                  <div className="cicle"></div>
                  {listCourse.watched ? (
                    <img src={checkIcon} alt="" className="imgCheck" />
                  ) : (
                    <div className="circle"></div>
                  )}
                  Aula concluída
                </button>
                {/* {listCourse.nextLesson && (
                  <button
                    className="checkVideo"
                    style={{
                      background: "#EC5569",
                      border: "1px solid #EC5569",
                      color: "#ffffff",
                      justifyContent: "flex-end",
                      gap: "10px",
                    }}
                    onClick={nextLeason}
                  >
                    Próxima aula
                    <img
                      src={arrowIcon}
                      alt=""
                      style={{
                        width: "7px",
                        height: "11px",
                        marginRight: "10px",
                      }}
                    />
                  </button>
                )} */}
              </div>

              {listCourse.nextLesson && (
                <>
                  <div className="textSugestao">Próxima aula:</div>
                  <div
                    className={`card ${
                      listCourse?.nextLesson?.watched && "card_checked"
                    }`}
                  >
                    <div
                      className={`thumbnail ${
                        listCourse?.nextLesson?.watched && "thumbnail_checked"
                      }`}
                    >
                      <img src={checkIcon} alt="" className="iconPLay" />
                      {thumbnailUrl && (
                        <img src={thumbnailUrl} alt="" className="thumb" />
                      )}
                    </div>
                    <div className="containerText">
                      <div className="title">
                        {listCourse?.nextLesson?.title}
                      </div>
                      <div>
                        <button
                          className="buttonVIdeo"
                          style={{
                            background: "#EC5569",
                            border: "1px solid #EC5569",
                            color: "#ffffff",
                          }}
                          onClick={nextLeason}
                        >
                          Ver aula
                          <img
                            src={arrowIcon}
                            alt=""
                            style={{
                              width: "7px",
                              height: "11px",
                              marginRight: "10px",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {listCourse.previousLesson && (
                <>
                  <div className="textSugestao">Aula anterior:</div>
                  <div
                    className={`card ${
                      listCourse?.previousLesson?.watched && "card_checked"
                    }`}
                  >
                    <div
                      className={`thumbnail ${
                        listCourse?.previousLesson?.watched &&
                        "thumbnail_checked"
                      }`}
                    >
                      <img src={checkIcon} alt="" className="iconPLay" />
                      {thumbnailUrl && (
                        <img src={thumbnailUrl} alt="" className="thumb" />
                      )}
                    </div>
                    <div className="containerText">
                      <div className="title">
                        {listCourse?.previousLesson?.title}
                      </div>
                      <div>
                        <button
                          className="buttonVIdeo"
                          style={{
                            background: "#0AC6BD",
                            border: "1px solid #0AC6BD",
                            color: "#ffffff",
                          }}
                          onClick={previousLesson}
                        >
                          Rever aula
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </>

      <div
        className={
          listCourse?.length <= 1 ? "footerMobileFixed" : "footerMobile"
        }
      >
        Desenvolvido com
        <img src={iconHeart} alt="" />
        <strong>© eComLovers®</strong>
      </div>
    </AuthorizedLayout>
  );
};
