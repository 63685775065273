import { useEffect, useState } from "react";
import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import iconHeart from "../../assets/Vector (23).png";
import iconAstro from "../../assets/image 10 (1).png";
import { Cursos } from "./components/cursos";
import { Lives } from "./components/lives";
import { Podcasts } from "./components/podcasts";

import "./Academy.css";

export const Academy = () => {
  const [activeItem, setActiveItem] = useState("Cursos");
  const [listCourses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleItemClick = (item) => {
    setCourses([]);
    localStorage.setItem("activeAcademyTab", item);
    setActiveItem(item);
  };

  const renderScreen = () => {
    switch (activeItem) {
      case "Podcasts":
        return <Podcasts loading={loading} setLoading={setLoading} listCourses={listCourses} setCourses={setCourses}/>;
      case "Lives":
        return <Lives loading={loading} setLoading={setLoading} listCourses={listCourses} setCourses={setCourses} />;
      default:
        return <Cursos loading={loading} setLoading={setLoading} listCourses={listCourses} setCourses={setCourses} />;
    }
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("@ecomlovers:activeAcademyTab");
    if (savedTab) {
      setActiveItem(savedTab);
    }
  }, []);

  return (
    <AuthorizedLayout>
      <div className="containerAcademy">
        <div className="containerHeaderAcademy">
          <div className="containerLeft">
            <div className="title">
              <span className="text1">Academy</span>
              <span className="text2">BETA</span>
            </div>
            <span className="subtitle">
              Confira aqui todas as aulas disponíveis para que você aprenda tudo
              o que precisa para criar o seu e-commerce!
            </span>
          </div>
          <div className="containerRigth">
            <img src={iconAstro} alt="" />
          </div>
        </div>
        <div className="menu-container">
          <div
            className={`menu-item ${activeItem === "Cursos" ? "active" : ""}`}
            onClick={() => handleItemClick("Cursos")}
          >
            Cursos
          </div>
          <div
            className={`menu-item ${activeItem === "Podcasts" ? "active" : ""}`}
            onClick={() => handleItemClick("Podcasts")}
          >
            Podcasts
          </div>
          <div
            className={`menu-item ${activeItem === "Lives" ? "active" : ""}`}
            onClick={() => handleItemClick("Lives")}
          >
            Lives
          </div>
        </div>

        {renderScreen()}
      </div>

        <div className={listCourses?.length <= 1 ? "footerMobileFixed" : "footerMobile"}>
          Desenvolvido com
          <img src={iconHeart} alt="" />
          <strong>© eComLovers®</strong>
        </div>
    </AuthorizedLayout>
  );
};
