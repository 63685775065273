import React, { useState, useEffect } from "react";
import { AuthorizedLayoutCompanies } from "../../components/AuthorizedLayoutCompanies";
import iconMoney from "../../assets/fi_10349139.png";
import iconCLose from "../../assets/Group (3).png";
import iconLoc from "../../assets/Vector (33).png";
import iconBack from "../../assets/Tipode material.png"
import FitCultural from "./components/FitCultural";
import Knowledge from "./components/Knowledge";
import SelfieLetter from "./components/SelfieLetter";
import Skills from "./components/Skills";

import api from "../../utils/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import "./DetailsTalent.css";
import LoadingIcon from "../../components/LoadingIcon";
import DetailChat from "../../components/ChatComponent/DetailChat";
import CardChat from "../../components/CardChat.js/CardChat.js";

const DetailsTalent = () => {
  const { id } = useParams();
  const [talents, setTalents] = useState([]);
  const [activeTab, setActiveTab] = useState("selfie");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from || "/";

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const fetcheTalent = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/companies/talents/${id}`);

      setTalents({
        id: data?.data?.id,
        ...data?.data?.attributes,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const breadcrumbBase = {
    '/companies/painel': "Página Inicial",
    '/companies/talentos': "Banco de Talentos",
  };

  useEffect(() => {
    fetcheTalent();
  }, []);

  function getFirstName(fullName) {
    return fullName?.trim()?.split(" ")[0];
  }

  return (
    <AuthorizedLayoutCompanies>
      {!loading ? (
        <>
          <div className="headerContainer">
            <div className="titlePageProfile">{talents?.name}</div>
            <div className="breadcrumbContainer">
              <div className="buttonBreadcrumb" onClick={() => navigate(from)}>
                <img src={iconBack} alt="" />
              </div>
              <div className="breadcrumb">{breadcrumbBase[from]} / <span className="active">Detalhes do Talento</span></div>
            </div>
          </div>
          <div className="containerBoxProfile">
            <div className="containerInfoProfile">
              <img src={talents?.avatar} alt="" className="avatarProfile" />
              <div className="subTitlePorfile">
                Profissional apaixonado por marketing digital e análise de
                dados, com experiência sólida em gestão de equipes e
                implementação de estratégias inovadoras.
              </div>

              <div className="cardInfoProfile">
                <div className="box1">
                  <div className="containerInfoBox">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "18px",
                        height: "18px",
                      }}
                    >
                      <img
                        src={iconMoney}
                        alt="  "
                        style={{ width: "16.13px", height: "16.13px" }}
                      />
                    </div>
                    <div className="text">De R$2.000 a R$4.000</div>
                  </div>

                  <div className="containerInfoBox">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "18px",
                        height: "18px",
                      }}
                    >
                      <img
                        src={iconCLose}
                        alt="  "
                        style={{ width: "11.29", height: "15.06px" }}
                      />
                    </div>
                    <div className="text">Mínimo não negociável</div>
                  </div>
                </div>

                <div className="box2">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                    }}
                  >
                    <img
                      src={iconLoc}
                      alt="  "
                      style={{ width: "12.38", height: "15.75px" }}
                    />
                    <div className="textState">{`${talents?.city || ""} - ${
                      talents?.state || ""
                    }`}</div>
                  </div>
                </div>
              </div>
              <CardChat infoTalents={talents}/>


              {talents?.culturalFitResult?.a ? (
                <FitCultural culturalFitResult={talents?.culturalFitResult} />
              ) : (
                ""
              )}
            </div>

            <div className="tabs-container">
              <div className="tabs-header">
                <button
                  className={`tab-button ${
                    activeTab === "selfie" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("selfie")}
                >
                  Selfie Letter
                </button>
                <button
                  className={`tab-button ${
                    activeTab === "conhecimentos" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("conhecimentos")}
                >
                  Conhecimentos
                </button>
                <button
                  className={`tab-button ${
                    activeTab === "habilidades" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("habilidades")}
                >
                  Habilidades
                </button>
              </div>
              <div className="tab-content">
                {activeTab === "conhecimentos" && (
                  <Knowledge talents={talents} />
                )}
                {activeTab === "selfie" && <SelfieLetter talents={talents} />}
                {activeTab === "habilidades" && <Skills talents={talents} />}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "150px",
            justifySelf: "center",
            marginTop: "250px",
          }}
        >
          <LoadingIcon />
        </div>
      )}
    </AuthorizedLayoutCompanies>
  );
};

export default DetailsTalent;
