import React, { useState, useEffect } from "react";
import jcom from "../../../assets/parceiros/image.png";
import vd from "../../../assets/parceiros/image-1.png";
import bdig from "../../../assets/parceiros/image-2.png";
import smar from "../../../assets/parceiros/image-3.png";
import pink from "../../../assets/parceiros/image-4.png";
import gobank from "../../../assets/parceiros/image-5.png";
import uni from "../../../assets/parceiros/image-6.png";

import './LogoCarousel.css';

const LogoCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const logos = [
    { id: 1, src: jcom, alt: "JCOM" },
    { id: 2, src: vd, alt: "VD VILLE" },
    { id: 3, src: bdig, alt: "2BDIGITAL" },
    { id: 4, src: smar, alt: "SMARKETS" },
    { id: 5, src: pink, alt: "PINK" },
    { id: 6, src: gobank, alt: "2GO BANK" },
    { id: 7, src: uni, alt: "UNI" },
  ];

  const duplicatedLogos = [...logos, ...logos];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === logos.length ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <div className="carousel-container">
        <div className="text">Marcas que já confiaram na eComLovers</div>
        <div className="carousel-wrapper">
          <div
            className="carousel-track"
            style={{
              transform: `translateX(-${currentIndex * (100 / 7)}%)`,
            }}
          >
            {duplicatedLogos.map((logo, index) => (
              <div key={`${logo.id}-${index}`} className="carousel-slide">
                <img src={logo.src} alt={logo.alt} />
              </div>
            ))}
          </div>
        </div>

        {/* <div className="carousel-dots">
          {logos.map((_, index) => (
            <button
              key={index}
              className={`carousel-dot ${currentIndex === index ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div> */}
      </div>
    </>
  );
};

export default LogoCarousel;
