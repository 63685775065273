import { useContext, useEffect, useState } from "react";
import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import { Box, LinearProgress, linearProgressClasses } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import api from "../../utils/api";
import { AuthContext } from "../../providers/AuthProvider";
import useIsMobileDevice from "../../utils/mobileCheck";
import iconHeart from "../../assets/Vector (23).png";
import astrounautStandingWave from "../../assets/posesPNG/astronout-standing-wave.png";
import astrounautStandingThinking from "../../assets/image 18 (2).png";
import iconCompanie from "../../assets/Artboard 3 copy 6.png";

import "./Inbox.css";
import "moment/locale/pt-br"; 
moment.locale("pt-br");

export const Inbox = () => {
  const isMobile = useIsMobileDevice();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
    const [menssages, setMenssages] = useState([]);

  const checkList = {
    isCulturalFitFilled: user?.isCulturalFitFilled,
    isSelfieLetterFilled: user?.isSelfieLetterFilled,
    isTalentKnowledgeAreaFilled: user?.isTalentKnowledgeAreaFilled,
    isTalentKnowledgeSkillFilled: user?.isTalentKnowledgeSkillFilled,
    userAVatar: user?.avatar !== null,
  };

  const contarTrues = (obj) => {
    let contador = 0;

    for (let key in obj) {
      if (obj[key] === true) {
        contador++;
      }
    }

    return contador;
  };

  const fetchMessages = async () => {
      // setIsFetching(true);

      try {
        const { data } = await api.get(`/talents/messages/`);

        setMenssages(
          data?.data.sort(function (a, b) {
            if (a.attributes.createdAt > b.attributes.createdAt) return -1;
            if (a.attributes.createdAt < b.attributes.createdAt) return 1;
            return 0;
          })
        );
      } catch (error) {
        console.error(error);
      } finally {
        // setIsFetching(false);
      }
  };

   useEffect(() => {
      fetchMessages();
    }, []);

  const handleNavigate = () => {
    navigate("/settings/status");
  };


  return (
    <AuthorizedLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: isMobile ? "90vw" : "100%",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",

              width: "100%",
              flexDirection: "column",
            }}
          >
            {contarTrues(checkList) <= 4 ? (
              <div className="containerCardAstr">
                <div className="cardCompleteProfile anamitionCard">
                  <div className="cardLeft">
                    <div className="textProfile">Complete seu perfil</div>
                    <LinearProgress
                      sx={{
                        height: 8,
                        borderRadius: 5,
                        [`&.${linearProgressClasses.colorPrimary}`]: {
                          backgroundColor: "#BEFCF9",
                          border: "2px solid #BEFCF9",
                        },
                        [`& .${linearProgressClasses.bar}`]: {
                          borderRadius: "5px 0px 0px 5px",
                          backgroundColor: "#57BAB8",
                        },
                      }}
                      value={((contarTrues(checkList) / 5) * 100).toFixed(2)}
                      variant="determinate"
                    />

                    <div className="textProfileSUb">
                      Perfis completos e de qualidade aumentam em 4,5 vezes suas
                      chances de ser contratado por clientes.
                    </div>

                    <div
                      className="button"
                      onClick={() => {
                        handleNavigate();
                      }}
                    >
                      Clique aqui
                    </div>
                  </div>

                  <div className="cardRigth">
                    <img
                      src={astrounautStandingWave}
                      alt="backgroud img"
                      style={{
                        position: "absolute",
                        width: "131px",
                        top: "-20px",
                        right: "8px",
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Box
                sx={{
                  backgroundColor: "#D8F6F5",
                  // border: "1px solid #57BAB8",
                  borderRadius: "10px",
                  width: "100%",
                  aspectRatio: 350 / 115,
                  marginBottom: "28px",
                  display: "flex",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    width: "60%",
                    padding: "24px",
                  }}
                >
                  <div className="titleFaq">Caixa de Mensagens</div>
                  <div className="subtitleFaq">
                    Confira aqui a sua Caixa de entrada. Te enviaremos
                    notificações através desse canal.
                  </div>
                </Box>

                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    width: "40%",
                  }}
                >
                  <img
                    src={astrounautStandingThinking}
                    style={{
                      position: "absolute",
                      height: "110%",
                      maxWidth: "100%",
                      objectFit: "contain",
                      top: "40%",
                      left: "45%",
                      transform: "translate(-50%, -50%)",
                    }}
                    alt=""
                  />
                </div>
              </Box>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: "space-around",
            }}
            className="conatinerInbox"
          >

            {menssages?.map((item, idx) => (
              <div
                className={`cardBox ${!item?.attributes?.readed && 'notChecked'}`}
                style={{ width :'100%'}}
                onClick={() => {
                  navigate(`/chat/${item?.id}/${item?.attributes?.receiver?.name}`);
                }}
              >
                <div className="cicleNoti" />
                <img src={iconCompanie} alt="" />

                <div className="containerText">
                  <div className="headerCardInbox">
                    <div className="title">{item?.attributes?.receiver?.name}</div>
                    <div className="text">{moment(item?.attributes?.createdAt).fromNow()}</div>
                  </div>

                  <div className="text">
                   {item?.attributes?.textMsg}
                  </div>

                  {/* <div className="text2">10 respostas</div> */}
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>

      <div className={menssages?.length <= 2 ? 'footerMobileFixed' : 'footerMobile'}>
        Desenvolvido com
        <img src={iconHeart} alt="" />
        <strong>© eComLovers®</strong>
      </div>
    </AuthorizedLayout>
  );
};
