import { useEffect, useState } from "react";
import { AuthorizedLayout } from "../../../../components/AuthorizedLayout";
import { useNavigate, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import api from "../../../../utils/api";
import iconBack from "../../../../assets/Tipode material.png";
import checkIcon from "../../../../assets/Frame 20 (8).png";
import photoProf from "../../../../assets/image (1).png";

import "./DetailsPodLive.css";
import YouTube from "react-youtube";
import LoadingIcon from "../../../../components/LoadingIcon";
import iconHeart from "../../../../assets/Vector (23).png";

export const DetailsPodLive = () => {
  const { id, type } = useParams();
  const navigate = useNavigate();
  const [listCourse, setCourse] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCourses = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/talents/media/${id}/details`);
      setCourse(
        {
          id: data?.data?.id,
          ...data?.data?.attributes,
        } || []
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCourses();
  }, [id]);

  const checkView = async () => {
    try {
      await api.post(`/talents/media/${listCourse?.id}/mark_watched`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigate = async () => {
    await checkView();
    navigate(-1);
  };

  const nextLeason = async () => {
    await checkView();
    navigate(
      `/${type === "podcast" ? "podcast" : "live"}/${
        listCourse?.relatedMedia?.title
      }/${listCourse?.relatedMedia?.id}`
    );
  };

  const getYouTubeVideoId = (url) => {
    try {
      const urlObj = new URL(url);
      if (urlObj.hostname === "youtu.be") {
        return urlObj.pathname.substring(1);
      }
      return urlObj.searchParams.get("v");
    } catch (error) {
      return null;
    }
  };

  const videoId = getYouTubeVideoId(listCourse?.url);

  const thumbnailUrl = (url) => {
    const videoId = getYouTubeVideoId(url);
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };

  const opts = {
    height: "197px",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      cc_load_policy: 0,
      controls: 2,
      fs: 1,
      iv_load_policy: 3,
      loop: 0,
      modestbranding: 1,
      origin: 0,
      playsinline: 0,
      rel: 0,
      showinfo: 0,
      start: 0,
    },
  };

  const listInstructor = (data) => {
    const instructors = data
      ?.filter((i) => i.instructorType === "instructor")
      .map((item) => item.name);

    return instructors?.length > 0 ? instructors.join(", ") + "." : false;
  };

  const listGuest = (data) => {
    const instructors = data
      ?.filter((i) => i.instructorType === "guest")
      .map((item) => item.name);

    return instructors?.length > 0 ? instructors.join(", ") + "." : false;
  };

  const listHost = (data) => {
    const instructors = data
      ?.filter((i) => i.instructorType === "host")
      .map((item) => item.name);

    return instructors?.length > 0 ? instructors.join(", ") + "." : false;
  };

  const truncateText = (name, maxLength = 7) => {
    if (!name) return "...";
    let truncatedName = name;

    if (name?.length > maxLength) {
      truncatedName = name.slice(0, maxLength - 3) + "...";
    }

    return truncatedName;
  };

  const labelCase = (label) => {
    switch (label) {
      case "guest":
        return "Convidado";
      case "host":
        return "Host";
      default:
        return "Professor";
    }
  };

  // console.log(listCourse);

  return (
    <AuthorizedLayout>
      <>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // height: "150px",
                width: "250px",
                marginTop: "50px",
              }}
            >
              <LoadingIcon />
            </div>
          </div>
        ) : (
          <div className="containerDetailsPodLive">
            <div className="containerDetails">
              <div className="containerHeader" onClick={handleNavigate}>
                <img src={iconBack} alt="" />
                {/* <span>{truncateText(listCourse?.title, 30)}</span> */}
                <span>VOLTAR</span>
              </div>
              <div style={{ borderRadius: "12px", overflow: "hidden" }}>
                <YouTube videoId={videoId} opts={opts} />
              </div>
              <div className="containerTeacher">
                <div className="headers">
                  <div className="containerText2">
                    <div>{listCourse?.title}</div>

                    {listHost(listCourse?.instructors) && (
                      <div className="text2">
                        Hosts:{" "}
                        {listCourse?.instructors && (
                          <span>{listHost(listCourse?.instructors)}</span>
                        )}
                      </div>
                    )}

                    {listInstructor(listCourse?.instructors) && (
                      <div className="text2">
                        Professores:{" "}
                        <span>{listInstructor(listCourse?.instructors)}</span>
                      </div>
                    )}

                    {listGuest(listCourse?.instructors) && (
                      <div className="text2">
                        Convidados:{" "}
                        {listCourse?.instructors && (
                          <span>{listGuest(listCourse?.instructors)}</span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="description">{listCourse?.description}</div>
              </div>

              <button
                className={`checkVideo ${listCourse?.watched && "checked"}`}
                style={{
                  flex: !listCourse.nextLesson && "none",
                  padding: "0px 8px",
                }}
                onClick={async () => {
                  await checkView();
                  await getCourses();
                }}
              >
                <div className="cicle"></div>
                {listCourse.watched ? (
                  <img src={checkIcon} alt="" className="imgCheck" />
                ) : (
                  <div className="circle"></div>
                )}
                {type === "podcast" ? "Podcast assistido" : "Live assistida"}
              </button>

              {listCourse?.instructors?.map((item) => (
                <div className="containerTeacher">
                  <div className="headers">
                    <img src={item?.photoUrl} alt="" className="imageProf" />
                    <div className="containerText">
                      <span>{labelCase(item?.instructorType)}</span>
                      <div>{item?.name}</div>
                    </div>
                  </div>
                  <div className="description">{item?.description}</div>
                </div>
              ))}

              {listCourse.relatedMedia &&
                listCourse?.relatedMedia?.length > 0 && (
                  <>
                    <div className="textSugestao">Veja também:</div>
                    <div className={`card`} onClick={nextLeason}>
                      <div className={`thumbnail`}>
                        <img src={checkIcon} alt="" className="iconPLay" />
                        {thumbnailUrl && (
                          <img src={thumbnailUrl} alt="" className="thumb" />
                        )}
                      </div>
                      <div className="containerText">
                        <div className="title">
                          {listCourse?.nextLesson?.title}
                        </div>
                        {/* <div>
                        {listInstructor(listCourse.instructors) && (
                          <div className="subtitle">
                            <span>{`Instrutor: ${truncateText(
                              listInstructor(listCourse.instructors)
                            )}`}</span>
                          </div>
                        )}
                        {listGuest(listCourse.instructors) && (
                          <div className="subtitle">
                            <span>{`Convidado: ${truncateText(
                              listGuest(listCourse.instructors)
                            )}`}</span>
                          </div>
                        )}
                      </div> */}
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
        )}
      </>

      <div
        className={
          listCourse?.length <= 1 ? "footerMobileFixed" : "footerMobile"
        }
      >
        Desenvolvido com
        <img src={iconHeart} alt="" />
        <strong>© eComLovers®</strong>
      </div>
    </AuthorizedLayout>
  );
};
