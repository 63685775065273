import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import iconSegure from "../../../assets/Frame 20 (9).png";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import useIsMobileDevice from "../../../utils/mobileCheck";
import systemIcon from "../../../assets/systemIcon.svg";
import checkIcon from "../../../assets/check.png";
import tooltipIcon from "../../../assets/tooltip.png";
import imageFInaly from "../../../assets/image 16 (1).png";
import LayoutSpace from "../../components/LayoutSpace";
import api from "../../../utils/api";
import "./Checkout.css";
import { Typography } from "@mui/material";
import unmask from "../../../utils/unmask";
import cardBrand from "../../../utils/cardBrand";
import StripeWrapper from "../../../utils/StripeWrapper";
import LogoCarousel from "./LogoCarousel";
import { AuthContext } from "../../../providers/AuthProvider";

const Checkout = ({ authToken }) => {
  const navigate = useNavigate();
  const { signOut } = useContext(AuthContext);
  const isMobile = useIsMobileDevice();
  const [currentScreen, setCurrentScreen] = useState("screen1");
  const [loading, setLoading] = useState(true);
  const [listPlans, setListPlans] = useState([]);
  const [cards, setCards] = useState([]);
  const [loadingCards, setLoadingCards] = useState(true);
  const [selectedCard, setSelectedCard] = useState({});
  const [saveCard, setSaveCard] = useState(false);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [planoSelected, setPlanoSelected] = useState();
  const [selectedPeriod, setSelectedPeriod] = useState("3 meses");

  const periods = ["3 meses", "6 meses", "1 ano"];

  const stylesContainer = currentScreen !== "screen1" && {
    alignItems: "start",
  };

  const stylesImage = currentScreen !== "screen1" && { height: "75vh" };

  const getPLans = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/companies/payment_plans`,
        authToken && {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (data.data) {
        const plansLists = data?.data.map((item) => ({
          id: item.id,
          ...item.attributes,
        }));
        setListPlans(plansLists);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPLans();
  }, []);

  // console.log(listPlans);

  const formLogin = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: yup.object({
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("Email é obrigatório!"),
    }),

    // onSubmit: (values) => handleContinue(values),
  });

  const step2Form = useFormik({
    initialValues: {
      cardFullName: "",
      cardNumber: "",
      cardExpirationDate: "",
      cardCVV: "",
    },
    validationSchema: yup.object({
      cardFullName: yup
        .string()
        .required("Nome no cartão é obrigatório")
        .min(3, "Nome deve ter pelo menos 3 caracteres"),
      cardNumber: yup
        .string()
        .required("Número do cartão é obrigatório")
        .matches(
          /^[0-9\s]{19}$/,
          "Número do cartão deve estar no formato 9999 9999 9999 9999"
        ),
      cardExpirationDate: yup
        .string()
        .required("Data de expiração é obrigatória")
        .matches(
          /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
          "Data de expiração deve estar no formato MM/AA"
        )
        .test("expDate", "Data de expiração inválida", (value) => {
          if (!value) return false;
          const today = new Date();
          const [month, year] = value.split("/").map(Number);
          const expDate = new Date(`20${year}`, month - 1);
          return expDate >= today;
        }),
      cardCVV: yup
        .string()
        .required("CVV é obrigatório")
        .matches(/^[0-9]{3,4}$/, "CVV deve ter 3 dígitos"),
    }),
    onSubmit: () => registerCard(),
  });

  const getCards = async () => {
    setLoadingCards(true);
    try {
      const { data: cards } = await api.get(`/partners/credit_cards`);

      if (cards) {
        //console.log(cards.data);
        setCards(
          cards.data.map((item, index) => {
            return {
              id: item.id,
              position: index,
              ...item.attributes,
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
      toast.error("Não foi possível carregar os dados dos cartões.");
    } finally {
      setLoadingCards(false);
    }
  };

  const registerCard = async () => {
    setSaveCard(true);
    try {
      const { data } = await api.post("/companies/credit_cards", {
        data: {
          attributes: {
            cardNumber: step2Form.values.cardNumber,
            cardHolderName: step2Form.values.cardFullName,
            expiresAt:
              "20" +
              unmask(step2Form.values.cardExpirationDate).slice(2, 4) +
              "-" +
              unmask(step2Form.values.cardExpirationDate).slice(0, 2),
            cardCvv: step2Form.values.cardCVV,
            brand: cardBrand(step2Form.values.cardNumber),
          },
        },
      });

      if (data) {
        // setModal(false);
        // step2Form.setValues(step2Form.initialValues);
        setSelectedCard(data.data);
        getCards();
        navigate("/financeiro/step3");
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.errors[0].title) {
        toast.error(error.response.data.errors[0].title);
      }
      return toast.error("Ocorreu um erro ao tentar cadastrar o cartão.");
    } finally {
      setSaveCard(false);
    }
  };

  const listsPLans = [
    {
      0: [
        "Perfil Business",
        "Acesso a base de talentos",
        "Filtro de Talentos",
        "Mensageria",
        "Suporte",
      ],
    },
    {
      1: [
        "Perfil Business",
        "Acesso a base de talentos",
        "Filtro de Talentos Avançado",
        "Mensageria",
        "Lista de Favoritos",
        "Suporte Whatsapp",
      ],
    },
    {
      2: [
        "Perfil Business",
        "Acesso a base de talentos",
        "Filtro de Talentos Avançado",
        "Mensageria",
        "Lista de Favoritos",
        "Suporte Whatsapp",
        "Key Account",
        "Academy",
      ],
    },
  ];

  const caseListPlan = (name) => {
    switch (name) {
      case "Padrão":
        return 0;
      case "Lovers":
        return 1;
      case "Lovers Plus":
        return 2;
      default:
        return 0;
    }
  };

  const PaymentScreen = () => {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true);

      // const { error } = await stripe.confirmPayment({
      //   elements,
      //   confirmParams: {
      //     // Make sure to change this to your payment completion page
      //     return_url: `${window.location.protocol}//${window.location.host}/companies/registro/completar`,
      //   },
      // });

      const { paymentIntent, error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href, // Mantém na mesma página
        },
        redirect: "if_required", // Evita redirecionamento automático
      });

     
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred.");
        }
      } else if (paymentIntent?.status === "succeeded") {
        setCurrentScreen("screen2"); // Altera a tela manualmente
      }

      setIsLoading(false);
    };

    return (
      <div className="containerPlans containerPAymentMethod">
        <div className="containerMessage" style={{ marginTop: "100px" }}>
          <div className="textBoxMessage">
            Você escolheu o <strong>{`Plano ${planoSelected?.name}`}</strong>,
            confira e finalize a sua contratação, este ambiente é seguro para
            pagamentos.
          </div>
        </div>

        <div className="containerCardCheckout">
          <div className="cardPLans" style={{ alignItems: "flex-start" }}>
            <div className="title" style={{ fontWeight: "300" }}>
              Plano escolhido: <strong>{planoSelected?.name}</strong>
            </div>

            <div className="value">
              <div className="subTitle">R$</div>
              <div>{planoSelected?.amount}</div>
            </div>

            <div className="listItens">
              {Object.values(
                listsPLans[caseListPlan(planoSelected?.name)]
              )[0]?.map((item, index) => (
                <div className="list" key={index}>
                  <div className="img">
                    <img src={checkIcon} alt="check icon" />
                  </div>
                  <div>{item}</div>
                  <div className="img">
                    <img src={tooltipIcon} alt="check icon" />
                  </div>
                </div>
              ))}
            </div>

            <button
              style={{
                height: "43px",
                width: "131px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",
              }}
              onClick={() => setCurrentScreen("screen1")}
            >
              Alterar plano
            </button>
          </div>

          <form className="containerCreditCard">
            <div className="containerSegureText">
              <img src={iconSegure} alt="" />
              <div className="containerText">
                <div className="title">Segurança em primeiro lugar!</div>
                <div className="subtitle">
                  Seu pagamento é processado com segurança pelo Stripe,
                  protegido por criptografia SSL.
                </div>
              </div>
            </div>
            {/* <div className="card">
              <div className="containerInput">
                <div className="title">Nome que está no cartão</div>
                <input
                  className="inputCredCard"
                  type="text"
                  name="cardFullName"
                  onChange={step2Form.handleChange}
                  onBlur={step2Form.handleBlur}
                  value={step2Form.values.cardFullName}
                />
                {step2Form.touched.cardFullName &&
                step2Form.errors.cardFullName ? (
                  <div className="error">{step2Form.errors.cardFullName}</div>
                ) : null}
              </div>

              <div className="containerInput">
                <div className="title">Número do Cartão</div>
                <InputMask
                  className="inputCredCard"
                  mask="**** **** **** 9999"
                  name="cardNumber"
                  onChange={step2Form.handleChange}
                  onBlur={() => {
                    step2Form.setFieldTouched("cardNumber", true);
                  }}
                  value={step2Form.values.cardNumber}
                >
                  {(inputProps) => <input {...inputProps} type="text" />}
                </InputMask>
                {step2Form.touched.cardNumber && step2Form.errors.cardNumber ? (
                  <div className="error">{step2Form.errors.cardNumber}</div>
                ) : null}
              </div>
            </div>

            <div className="card" style={{ flexDirection: "row" }}>
              <div className="containerInput">
                <div className="title">Data de validade</div>
                <InputMask
                  className="inputCredCard"
                  mask="99/99"
                  name="cardExpirationDate"
                  onChange={step2Form.handleChange}
                  onBlur={step2Form.handleBlur}
                  value={step2Form.values.cardExpirationDate}
                >
                  {(inputProps) => <input {...inputProps} type="text" />}
                </InputMask>
                {step2Form.touched.cardExpirationDate &&
                step2Form.errors.cardExpirationDate ? (
                  <div className="error">
                    {step2Form.errors.cardExpirationDate}
                  </div>
                ) : null}
              </div>

              <div className="containerInput">
                <div className="title">Código de Segurança</div>
                <InputMask
                  className="inputCredCard"
                  mask="999"
                  name="cardCVV"
                  onChange={step2Form.handleChange}
                  onBlur={step2Form.handleBlur}
                  value={step2Form.values.cardCVV}
                >
                  {(inputProps) => <input {...inputProps} type="text" />}
                </InputMask>
                {step2Form.touched.cardCVV && step2Form.errors.cardCVV ? (
                  <div className="error">{step2Form.errors.cardCVV}</div>
                ) : null}
              </div>
            </div>

            <div className="containerInput">
              <div className="title">CPF/CNPJ</div>
              <input
                className="inputCredCard"
                type="text"
                name="cardFullName"
                onChange={step2Form.handleChange}
                onBlur={step2Form.handleBlur}
                value={step2Form.values.cardFullName}
              />
              {step2Form.touched.cardFullName &&
              step2Form.errors.cardFullName ? (
                <div className="error">{step2Form.errors.cardFullName}</div>
              ) : null}
            </div>*/}

            <PaymentElement
              id="payment-element"
              options={paymentElementOptions}
            />
            {stripe && elements && (
              <button
                onClick={handleSubmit}
                disabled={isLoading}
                style={{
                  border: "1px solid #0AC6BD",
                  backgroundColor: "#0AC6BD",
                  marginTop: "10px",
                }}
              >
                {isLoading ? '...Enviando' : 'Vamos começar agora!'}
              </button>
            )}
          </form>
        </div>

        <LogoCarousel />
      </div>
    );
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case "screen1":
        return (
          <div className="containerPlans">
            <div className="containerMessage">
              <div className="textBoxMessage">
                Já temos informações o suficiente para te oferecer o melhor fit
                para solucionar os seus problemas!
              </div>
            </div>

            <div className="period-selector">
              {periods.map((period) => (
                <button
                  key={period}
                  onClick={() => setSelectedPeriod(period)}
                  className={`period-button ${
                    selectedPeriod === period ? "active" : ""
                  }`}
                >
                  {period}
                </button>
              ))}
            </div>

            <div className="containerCardPlans">
              {listPlans
                ?.sort((a, b) => {
                  const sizeA = a?.amount;
                  const sizeB = b?.amount;
                  return sizeA - sizeB; // Ordena do menor para o maior
                })
                ?.map((item, idx) => (
                  <div
                    className={`cardPLans ${idx === 1 && "cardCentral"}`}
                    key={idx}
                  >
                    {idx === 1 && (
                      <div className="ofertPlan">OFERTA POPULAR</div>
                    )}
                    <div className="title">{item?.name}</div>
                    {/* <div className="subTitle">{item.subtitle}</div> */}

                    <div className="value">
                      <div className="subTitle">R$</div>
                      <div>{item?.amount}</div>
                    </div>

                    <button
                      onClick={() => {
                        setCurrentScreen("screen3");
                        setPlanoSelected(item);
                      }}
                      style={{ backgroundColor: idx === 1 && "#0AC6BD" }}
                    >
                      Quero esse plano!
                    </button>
                    <div className="listItens">
                      {Object.values(
                        listsPLans[caseListPlan(item?.name)]
                      )[0]?.map((item, index) => (
                        <div className="list" key={index}>
                          <div className="img">
                            <img src={checkIcon} alt="check icon" />
                          </div>
                          <div>{item}</div>

                          <div className="img">
                            <img src={tooltipIcon} alt="check icon" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        );

      case "screen2":
        return (
          <div className="containerPlans">
            <div className="containerSucess">
              <img src={imageFInaly} alt="" />
              <div className="title">Plano contratado com sucesso!</div>
              <div className="text">
                Boas-vindas à eComLovers! Faça o login e comece a buscar os
                melhores talentos 🥳
              </div>
              <button onClick={() =>   navigate("/companies")}>
                Fazer login
              </button>
            </div>
          </div>
        );
      default:
        return (
          <StripeWrapper plan={planoSelected?.id} authToken={authToken}>
            <PaymentScreen />
          </StripeWrapper>
        );
    }
  };

  const joinStrings = (strings) => {
    return strings.join("");
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return <>{renderScreen(currentScreen)}</>;
  // return (
  //
  // );
};

export default Checkout;
