import React, { useContext, useEffect, useState } from "react";
import { AuthorizedLayoutCompanies } from "../../components/AuthorizedLayoutCompanies";
import { AuthContext } from "../../providers/AuthProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import iconSearch from "../../assets/Frame 21 (1).png";
import cicleFog from "../../assets/Frame 427320212.png";
import iconLOcal from "../../assets/Vector (28).png";
import iconLArrow from "../../assets/Vector (29).png";
import { TalentCard } from "../../components/TalentCard";
import "./Painels.css";
import api from "../../utils/api";

const Painels = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [listFavorites, setListFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState("todos");
  const [filteredList, setFilteredList] = useState(listFavorites);
  const location = useLocation();

  const getFavorites = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/companies/favorites`);

      if (data.data) {
        const talentLists = data?.data.map((item) => ({
          id: item.id,
          ...item.attributes,
        }));
        setListFavorites(talentLists);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFavorites();
  }, []);

  const itemsPerPage = 3;

  useEffect(() => {
    let newFilteredList = [...listFavorites];

    // Aplica o filtro de busca por nome
    if (searchTerm) {
      newFilteredList = newFilteredList.filter((talent) =>
        talent?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );
    }

    // Aplica o filtro por tipo
    if (filterType !== "todos") {
      newFilteredList = newFilteredList.filter(
        (talent) => talent.type === filterType
      );
    }

    setFilteredList(newFilteredList);
    setCurrentPage(1); // Reseta para a primeira página quando filtrar
  }, [searchTerm, filterType, listFavorites]);

  // Calcula o total de páginas
  const totalPages = Math.ceil(filteredList?.length / itemsPerPage);

  // Obtém os itens da página atual
  const getCurrentItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredList?.slice(startIndex, endIndex);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Handler para o input de busca
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <AuthorizedLayoutCompanies>
      <div className="containerPainelHOme">
        <div className="headerPainel">
          <div>
            <div className="titlePanel">{`Olá ${user.name}!`}</div>
            <div className="subtitlePanel">{`Seja bem-vindo(a) de volta ☺️`}</div>
          </div>
          <button
            className="btton"
            onClick={() => navigate(`/companies/talentos`)}
          >
            Buscar talentos
          </button>
        </div>

        <div className="contentContainer">
          <div className="conainerLeft">
            <img src={cicleFog} alt="" />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "3px" }}
            >
              <div className="titlePanel">{user?.companyName}</div>
              <div className="titleContent">
                <strong>Plano atual: </strong>Plano Básico
              </div>
            </div>

            <div className="subtitlePanel">
              <img src={iconLOcal} alt="" />
              {`${user?.address?.city} - ${user?.address?.state}`}
            </div>

            <div className="subtitlePanel">
              Com sede em São Paulo e operações em todo o território nacional, a
              Ecommerce Pro trabalha com tecnologia de ponta para otimizar o
              atendimento e a entrega de pedidos, consolidando-se como
              referência em confiabilidade e excelência no comércio eletrônico.
            </div>
          </div>

          <div className="conainerRight">
            <div className="talent-list-container">
              <div className="headerTitle">
                <div className="titlePanel">Talentos Favoritos</div>

                <div className="containerFilters">
                  <div className="text">
                    <strong>
                      {currentPage}-{totalPages}
                    </strong>{" "}
                    de <strong>{totalPages}</strong>
                  </div>
                  <div className="search-container">
                    <input
                      placeholder="Pesquisar talento"
                      variant="outlined"
                      size="small"
                      value={searchTerm}
                      onChange={handleSearch}
                    />

                    <img
                      src={iconSearch}
                      alt="sarch"
                      style={{ width: "30px" }}
                    />
                  </div>
                </div>
              </div>

              {loading || getCurrentItems()?.length > 0 ? (
                <div className="talent-list">
                  {getCurrentItems().map((talent) => (
                    <TalentCard key={talent.id} talent={talent} onUpdateFavorites={getFavorites} />
                  ))}
                </div>
              ) : (
                <>
                  <div className="titleContent">Você ainda não tem talentos favoritos!</div>
                  <div className="textContent">
                    Explore os perfis e marque seus talentos favoritos para encontrá-los facilmente aqui.
                  </div>

                  <row style={{alignSelf: 'center', marginTop: 24}}>
                      <button
                        className="btton"
                        onClick={() => navigate(`/companies/talentos`)}
                      >
                        Buscar talentos
                      </button>
                  </row>
                </>
              )}

              {getCurrentItems()?.length > 0 && (
                <div className="containerPainationPainel">
                  <button
                    onClick={prevPage}
                    disabled={currentPage === 1}
                    className=""
                  >
                    <img
                      src={iconLArrow}
                      alt="sarch"
                      // style={{ width: "8px", height: "12px" }}
                    />
                    Anterior
                  </button>

                  <span className="text">{currentPage}</span>

                  <button
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                  >
                    Próxima
                    <img
                      src={iconLArrow}
                      alt="sarch"
                      style={{ transform: "rotate(180deg)" }}
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AuthorizedLayoutCompanies>
  );
};

export default Painels;
