import React, { useEffect, useState } from "react";

import iconSearch from "../../../assets/Frame 21 (1).png";
import iconLoc from "../../../assets/Vector (33).png";
import iconMoney from "../../../assets/HandCoins.png";
import iconFInance from "../../../assets/fi_10349139 (1).png";
import iconSelfie from "../../../assets/Group 1433.png";
import iconHeart from "../../../assets/Vector (30).png";
import iconHabilits from "../../../assets/fi_1242494.png";
import iconFit from "../../../assets/fi_1536828.png";
import heart from "../../../assets/heartIcon.svg";
import heartInactive from "../../../assets/heartIconInactive.svg";

import "./SearchFilter.css";
import { Rating, Slider } from "@mui/material";
import ExpandableList from "./ExpandableList";
import axios from "axios";
import hexToRgba from "../../../utils/hexToRgba";

const SearcFilter = ({
  handleFilterChange,
  filters,
  setFilters,
  fetchTalents,
  brazilStates,
  loading,
}) => {
  const [municipios, setMunicipios] = useState([]);
  const [idState, setdState] = useState(null);

  const getMUnicipios = async (id) => {
    setMunicipios([]);
    try {
      const data = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id}/municipios`
      );
      if (data) {
        setMunicipios(data?.data);
        sessionStorage.setItem("idState", id);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (idState) {
      getMUnicipios(idState);
    }
  }, [idState]);

  const handleTooltip = (value) => {
    const knowledgeAreas = {
      0: "Quero aprender",
      1: "Sei o básico",
      2: "Me viro bem",
      3: "Posso ensinar",
    };

    return knowledgeAreas[value] || null;
  };

  const handleRatingChange = async (event, newValue, id) => {
    const selectedItem = filters?.knowledge?.find((idItem) => id === idItem.id);
    const updatedItems = filters?.knowledge?.filter(
      (idItem) => id !== idItem.id
    );

    handleFilterChange("knowledge", [
      ...updatedItems,
      { ...selectedItem, level: newValue },
    ]);
  };

  const MessageButton = ({ text }) => {
    return (
      <button
        className="message-button"
        style={{ opacity: text !== null ? 1 : 0 }}
      >
        {text}
      </button>
    );
  };

  const filterCheckedSkills = (skills) => {
    const result = [];

    // Itera sobre cada categoria de skills
    for (const category of skills) {
      // Para cada categoria, obtém o nome da categoria (primeira chave)
      const categoryName = Object.keys(category)[0];

      // Filtra apenas os itens checados dentro dessa categoria
      const checkedItems = category[categoryName].filter(
        (item) => item.checked === true
      );

      // Mapeia apenas id e level dos itens checados e adiciona ao resultado
      checkedItems.forEach((item) => {
        result.push({
          name: item.name,
          level: item.level,
        });
      });
    }

    return result || null;
  };

  const filterCheckedKnoledge = (skills) =>
    skills
      ?.filter((item) => item.checked)
      .map(({ name, level }) => ({ name, level })) || null;

  const cardsKnoledge = filterCheckedKnoledge(filters.knowledge);

  const cardsSkils = filterCheckedSkills(filters.skills);

  const sliderHandleColor = (value) => {
    switch (value) {
      case 0:
        return "black";
      case 1:
        return "#EC5569";
      case 2:
        return "#ECA355";
      case 3:
        return "#009ABC";
      case 4:
        return "#57BAB8";
      default:
        return null;
    }
  };

  const handleValueLabelFormat = (value) => {
    switch (value) {
      case 0:
        return "Não conheço";
      case 1:
        return "Sei o básico";
      case 2:
        return "Estou aprendendo";
      case 3:
        return "Me viro bem";
      case 4:
        return "Sou expert";
      default:
        return null;
    }
  };

  const labelProfile = {
    i: "Idealizador",
    c: "Comunicador",
    a: "Analítico",
    e: "Executor",
  };

  const [visibleCount, setVisibleCount] = useState(3); // Começa mostrando 5 itens
  const [showAll, setShowAll] = useState(false);

  const visibleItems = showAll
    ? filters?.knowledge
    : filters?.knowledge?.slice(0, visibleCount);

  const handleShowMore = () => {
    setVisibleCount(visibleCount + 3); // Adiciona mais 5 itens a cada clique
    if (visibleCount >= filters?.knowledge?.length) {
      setShowAll(true); // Se todos os itens estiverem visíveis, muda para o modo "Ver Menos"
    }
  };

  const handleShowLess = () => {
    setVisibleCount(3); // Volta a mostrar apenas os 5 primeiros
    setShowAll(false);
  };

  // New remove handler functions
  const handleRemoveFilter = (filterType, value) => {
    switch (filterType) {
      case "location_state":
        handleFilterChange("location", { ...filters.location, state: "" });
        break;
      case "location_city":
        handleFilterChange("location", { ...filters.location, city: "" });
        break;
      case "salary_min":
        handleFilterChange("salary", { ...filters.salary, min: "" });
        break;
      case "salary_max":
        handleFilterChange("salary", { ...filters.salary, max: "" });
        break;
      case "salary_open":
        handleFilterChange("salary", { ...filters.salary, open: false });
        break;
      case "knowledge":
        const updatedKnowledge = filters.knowledge.map((item) =>
          item.name === value ? { ...item, checked: false } : item
        );
        handleFilterChange("knowledge", updatedKnowledge);
        break;
      case "skills":
        // Updating skills is more complex because of the nested structure
        const updatedSkills = [...filters.skills];
        for (let i = 0; i < updatedSkills.length; i++) {
          const categoryName = Object.keys(updatedSkills[i])[0];
          const items = updatedSkills[i][categoryName];

          for (let j = 0; j < items.length; j++) {
            if (items[j].name === value) {
              items[j].checked = false;
              break;
            }
          }
        }
        handleFilterChange("skills", updatedSkills);
        break;
      case "primary":
        handleFilterChange("primary", "");
        break;
      case "secondary":
        handleFilterChange("secondary", "");
        break;
      case "balanced":
        handleFilterChange("balanced", false);
        break;
      default:
        break;
    }
  };

  return (
    <div className="sidebarFilter">
      <div className="search-container">
        <input
          type="text"
          placeholder="Pesquisar filtro"
          className="search-input"
        />
        <img src={iconSearch} alt="" />
      </div>

      {/* <div className="selected-filters">
        <span className="filter-tag">
          Gestão de Pessoas
          <span className="material-icons">favorite</span>
          <button>&times;</button>
        </span>
      </div> */}

      <div className="title">Filtros selecionados</div>

      <div className="containerFIltersSelecteds">
        {filters?.location?.state && (
          <div className="cardFilterSelected">
            <img src={iconLoc} alt="" />
            <div className="text">
              {
                brazilStates?.find(
                  (item) => Number(item.id) === Number(filters?.location?.state)
                )?.nome
              }
            </div>
            <button
              className="remove-filter-btn"
              onClick={() => handleRemoveFilter("location_state")}
            >
              ×
            </button>
          </div>
        )}

        {filters?.location?.city && (
          <div className="cardFilterSelected">
            <img src={iconLoc} alt="" />
            <div className="text">{filters?.location?.city}</div>
            <button
              className="remove-filter-btn"
              onClick={() => handleRemoveFilter("location_city")}
            >
              ×
            </button>
          </div>
        )}

        {filters?.salary?.min && (
          <div className="cardFilterSelected">
            <img src={iconMoney} alt="" />
            <div className="text">{`a partir de R$ ${filters?.salary?.min}`}</div>
            <button
              className="remove-filter-btn"
              onClick={() => handleRemoveFilter("salary_min")}
            >
              ×
            </button>
          </div>
        )}

        {filters?.salary?.max && (
          <div className="cardFilterSelected">
            <img src={iconMoney} alt="" />
            <div className="text">{`até R$ ${filters?.salary?.max}`}</div>
            <button
              className="remove-filter-btn"
              onClick={() => handleRemoveFilter("salary_max")}
            >
              ×
            </button>
          </div>
        )}

        {filters?.salary?.open && (
          <div className="cardFilterSelected">
            <img src={iconMoney} alt="" />
            <div className="text">Aberto a negociações</div>
            <button
              className="remove-filter-btn"
              onClick={() => handleRemoveFilter("salary_open")}
            >
              ×
            </button>
          </div>
        )}

        {cardsKnoledge &&
          cardsKnoledge?.map((item, idx) => (
            <div className="cardFilterSelected">
              <div className="text">{item?.name}</div>
              <Rating
                defaultValue={item.level}
                precision={1}
                // readOnly
                max={3}
                icon={<img src={heart} alt="heart" style={{ width: "12px" }} />}
                emptyIcon={
                  <img
                    src={heartInactive}
                    alt="heart"
                    style={{ width: "12px" }}
                  />
                }
                sx={{
                  "& .MuiRating-icon": {
                    margin: "1px", // Espaço entre os ícones
                  },
                }}
              />

              <button
                className="remove-filter-btn"
                onClick={() => handleRemoveFilter("knowledge", item.name)}
              >
                ×
              </button>
            </div>
          ))}

        {cardsSkils &&
          cardsSkils.map((item, idx) => (
            <div className="cardFilterSelected">
              <div className="text">{item?.name}</div>
              <Slider
                style={{
                  margin: "0px",
                  width: "30px",
                  padding: "0px",
                }}
                sx={{
                  "& .MuiSlider-thumb:hover": {
                    boxShadow: `0px 0px 0px 8px ${hexToRgba(
                      sliderHandleColor(item.level),
                      0.16
                    )}`,
                  },
                  "& .MuiSlider-thumb.Mui-active": {
                    boxShadow: `0px 0px 0px 14px ${hexToRgba(
                      sliderHandleColor(item.level),
                      0.16
                    )}`,
                  },
                  "& .MuiSlider-thumb": {
                    width: 0, // Adjust thumb size
                    height: 0, // Adjust thumb size
                    marginLeft: "-8px",
                  },
                  "& .MuiSlider-track": {
                    height: 6, // Ensure track height matches your design
                  },
                }}
                slotProps={{
                  track: {
                    style: {
                      backgroundColor: sliderHandleColor(item.level),
                      // borderColor: sliderHandleColor(item.level),
                      border: "0.95px solid #ABABAB",
                      height: "6px",
                    },
                  },
                  thumb: {
                    style: {
                      backgroundColor: "#575757",
                      marginLeft:
                        item.level === 0 || item.level === undefined
                          ? "8px"
                          : null,
                      border: "0px solid #ffff",
                      ":hover": {
                        color: sliderHandleColor(item.level),
                      },
                    },
                  },
                  rail: {
                    style: {
                      backgroundColor: "#FFFFFF",
                      height: "6px",
                      border: "0.95px solid #ABABAB",
                      width: "100%",
                    },
                  },
                }}
                min={0}
                defaultValue={0}
                value={item.level}
                max={4}
                valueLabelFormat={handleValueLabelFormat}
                valueLabelDisplay="auto"
              />

              <button
                className="remove-filter-btn"
                onClick={() => handleRemoveFilter("skills", item.name)}
              >
                ×
              </button>
            </div>
          ))}

        {filters?.primary && (
          <div className="cardFilterSelected">
            {/* <img src={iconMoney} alt="" /> */}
            <div className="text">{labelProfile[filters?.primary]}</div>
            <button
              className="remove-filter-btn"
              onClick={() => handleRemoveFilter("primary")}
            >
              ×
            </button>
          </div>
        )}

        {filters?.secondary && (
          <div className="cardFilterSelected">
            {/* <img src={iconMoney} alt="" /> */}
            <div className="text">{labelProfile[filters?.secondary]}</div>
            <button
              className="remove-filter-btn"
              onClick={() => handleRemoveFilter("secondary")}
            >
              ×
            </button>
          </div>
        )}

        {filters?.balanced && (
          <div className="cardFilterSelected">
            {/* <img src={iconMoney} alt="" /> */}
            <div className="text">Perfil equilibrado</div>
            <button
              className="remove-filter-btn"
              onClick={() => handleRemoveFilter("balanced")}
            >
              ×
            </button>
          </div>
        )}
      </div>

      <div className="filter-section">
        <div className="input">
          <div className="subText">Estado</div>
          <select
            className="location-select"
            disabled={brazilStates?.length <= 0}
            onChange={(e) => {
              getMUnicipios(e.target.value);
              handleFilterChange("location", {
                ...filters.location,
                state: e.target.value,
              });
            }}
            value={filters.location.state}
          >
            <option
              className="inputListSelect"
              // disabled={true}
              value={""}
            >
              Selecione um estado
            </option>
            {brazilStates
              ?.sort((a, b) => a.nome.localeCompare(b.nome)) // Ordena os estados pelo nome
              ?.map((item, index) => (
                <option key={index} value={item.id} className="inputListSelect">
                  {item.nome}
                </option>
              ))}
          </select>
        </div>

        <div className="input">
          <div className="subText">Cidade</div>
          <select
            className="location-select"
            disabled={municipios?.length <= 0}
            onChange={(e) => {
              handleFilterChange("location", {
                ...filters.location,
                city: e.target.value,
              });
            }}
            value={filters.location.city}
          >
            <option
              className="inputListSelect"
              // disabled={true}
              value={""}
            >
              Selecione uma cidade
            </option>
            {municipios
              ?.sort((a, b) => a.nome.localeCompare(b.nome)) // Ordena os estados pelo nome
              ?.map((item, index) => (
                <option
                  key={index}
                  value={item.nome}
                  className="inputListSelect"
                >
                  {item.nome}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className="title">
        <img src={iconFInance} alt="" />
        Pretensão Salarial
      </div>

      <div className="filter-section">
        <div className="inputsContainer">
          <div className="salary-inputs">
            <div className="subText">Mínimo</div>
            <input
              type="number"
              value={filters.salary.min}
              onChange={(e) =>
                handleFilterChange("salary", {
                  ...filters.salary,
                  min: e.target.value,
                })
              }
              style={{ width: "90%" }}
              className="inputClass"
              placeholder="Mínimo"
            />
          </div>
          <div className="salary-inputs">
            <div className="subText">Máximo</div>
            <input
              type="number"
              value={filters.salary.max}
              onChange={(e) =>
                handleFilterChange("salary", {
                  ...filters.salary,
                  max: e.target.value,
                })
              }
              style={{ width: "90%" }}
              className="inputClass"
              placeholder="Máximo"
            />
          </div>
        </div>
        <label className="check-label">
          <input
            type="checkbox"
            name="salary"
            value={filters.salary.open}
            checked={filters.salary.open}
            onChange={(e) =>
              handleFilterChange("salary", {
                ...filters.salary,
                open: !filters.salary.open,
              })
            }
          />
          Aberto a negociações
        </label>
      </div>

      <div className="title">
        <img src={iconSelfie} alt="" />
        Selfie Letter
      </div>

      <div className="filter-section">
        <div className="radio-group">
          <label className="radio-label">
            <input
              type="radio"
              name="selfie"
              value="optional"
              checked={!filters.selfieLetter}
              onChange={(e) => handleFilterChange("selfieLetter", false)}
            />
            Opcional
          </label>
          <label className="radio-label">
            <input
              type="radio"
              name="selfie"
              value="required"
              checked={filters.selfieLetter}
              onChange={() => handleFilterChange("selfieLetter", true)}
            />
            Obrigatória
          </label>
        </div>
      </div>

      <div className="title">
        <img src={iconHeart} alt="" />
        Conhecimentos
      </div>

      <div className="filter-section">
        {filters?.knowledge
          ?.slice()
          .sort((a, b) => a.name.localeCompare(b.name)) // Ordena alfabeticamente pelo nome
          .map((item) => (
            <div key={item.id} className={`card ${item.checked && "checked"}`}>
              <label className="check-label">
                <input
                  type="checkbox"
                  name="knowledge"
                  value={item.checked}
                  checked={item.checked}
                  onChange={() => {
                    // Lógica para atualizar o estado quando um item é marcado
                    const updatedKnowledge = filters.knowledge.map((k) =>
                      k.id === item.id ? { ...k, checked: !k.checked } : k
                    );
                    handleFilterChange("knowledge", updatedKnowledge);
                  }}
                />
              </label>
              <div className="conteinerNivel">
                <div className="text">{item.name}</div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Rating
                    defaultValue={item.level}
                    precision={1}
                    max={3}
                    icon={<img src={heart} alt="heart" />}
                    emptyIcon={<img src={heartInactive} alt="heart" />}
                    onChange={(e, newValue) =>
                      handleRatingChange(e, newValue, item.id)
                    }
                    sx={{
                      "& .MuiRating-icon": {
                        margin: "2px", // Espaço entre os ícones
                      },
                    }}
                  />
                  {item.level && item.level > 0 ? (
                    <MessageButton text={handleTooltip(item?.level)} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ))}

        {/* {filters?.knowledge?.length > visibleCount && !showAll && (
          <button className="load-more-button" onClick={handleShowMore}>
            Ver Mais
          </button>
        )}

        {showAll && (
          <button className="load-more-button" onClick={handleShowLess}>
            Ver Menos
          </button>
        )} */}
      </div>

      <div className="filter-section" style={{ gap: 0 }}>
        <div className="title">
          <img src={iconHabilits} alt="" />
          Habilidades
        </div>
        <ExpandableList
          filters={filters}
          handleFilterChange={handleFilterChange}
        />
      </div>

      <div className="title">
        <img src={iconFit} alt="" />
        Fit Cultural
      </div>

      <div className="filter-section">
        <div className="input">
          <div className="subText">Perfil dominante</div>
          <select
            className="location-select"
            onChange={(e) => handleFilterChange("primary", e.target.value)}
            value={filters.primary}
          >
            <option value="">Selecionar perfil</option>
            <option value="i">Idealizador</option>
            <option value="c">Comunicador</option>
            <option value="a">Analítico</option>
            <option value="e">Executor</option>
          </select>
        </div>

        <div className="input">
          <div className="subText">Perfil secundário</div>
          <select
            className="location-select"
            onChange={(e) => handleFilterChange("secondary", e.target.value)}
            value={filters.secondary}
          >
            <option value="">Selecionar perfil</option>
            <option value="i">Idealizador</option>
            <option value="c">Comunicador</option>
            <option value="a">Analítico</option>
            <option value="e">Executor</option>
          </select>
        </div>

        <label className="check-label">
          <input
            type="checkbox"
            name="salary"
            value={filters.balanced}
            checked={filters.balanced}
            onChange={() => handleFilterChange("balanced", !filters.balanced)}
          />
          Perfil equilibrado
        </label>
      </div>

      <button className="search-button" onClick={fetchTalents}>
        {loading ? "Carregando..." : "Buscar talentos"}
      </button>
    </div>
  );
};

export default SearcFilter;
