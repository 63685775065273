import React, { useState, useEffect } from "react";
import { AuthorizedLayoutCompanies } from "../../components/AuthorizedLayoutCompanies";
import SearchFilter from "./components/SearchFilter";
import iconSearch from "../../assets/Frame 21 (1).png";
import iconLArrow from "../../assets/Vector (29).png";
import { TalentCard } from "../../components/TalentCard";
import "./TalentSearch.css";
import api from "../../utils/api";
import { toast } from "react-toastify";
import axios from "axios";
import LoadingIcon from "../../components/LoadingIcon";

const TalentSearch = () => {
  const [filters, setFilters] = useState({
    location: {
      state: "",
      city: "",
    },
    salary: {
      min: "",
      max: "",
      open: false,
    },
    selfieLetter: false,
    knowledge: [],
    skills: [],
    visibleSkills: {},
    primary: "",
    secondary: "",
    balanced: false,
  });
  const [talents, setTalents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [brazilStates, setBrazilStates] = useState([]);
  const [filterType, setFilterType] = useState("todos");
  const [filteredList, setFilteredList] = useState(talents);

  const itemsPerPage = 15;

  const skillaAreas = {
    1: "operation",
    2: "marketing",
    3: "marketplace",
    4: "platform",
    5: "erp",
    6: "designAndMedia",
    7: "dataAndBi",
    8: "socialMedia",
    9: "ia",
    10: "dayToDay",
  };

  const knowledgeAreas = {
    1: "knowledgeSkills",
  };

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const { data } = await axios.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
        );
        setBrazilStates(data);
      } catch (error) {
        console.error("Erro ao carregar estados:", error);
      }
    };

    fetchStates();
  }, []);

  const getFavorite = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/companies/talents/filters`);

      if (data.data) {
        const knowledgeList = Object.values(knowledgeAreas).flatMap(
          (area) =>
            data?.data?.attributes?.knowledgeAreas?.[area]?.map((item) => ({
              id: item.id,
              level: 0,
              name: item.name,
              checked: false,
            })) || []
        );

        const skillsList = Object.entries(skillaAreas).map(([_, area]) => ({
          [area]:
            data?.data?.attributes?.knowledgeAreas?.[area]?.map((item) => ({
              id: item.id,
              level: 0,
              name: item.name,
              checked: false,
              logo: item.logo,
            })) || [],
        }));

        setFilters((prevFilters) => ({
          ...prevFilters,
          knowledge: knowledgeList,
          skills: skillsList,
        }));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFavorite();
  }, []);

  useEffect(() => {
    let newFilteredList = [...talents];

    // Aplica o filtro de busca por nome
    if (searchTerm) {
      newFilteredList = newFilteredList?.filter((talent) =>
        talent?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );
    }

    // Aplica o filtro por tipo
    if (filterType !== "todos") {
      newFilteredList = newFilteredList?.filter(
        (talent) => talent.type === filterType
      );
    }

    setFilteredList(newFilteredList);
    setCurrentPage(1); // Reseta para a primeira página quando filtrar
  }, [searchTerm, filterType, talents]);

  // Calcula o total de páginas
  const totalPages = Math.ceil(filteredList?.length / itemsPerPage);

  // Obtém os itens da página atual
  const getCurrentItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredList?.slice(startIndex, endIndex);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // Handler para o input de busca
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (category, value) => {
    setFilters((prev) => ({
      ...prev,
      [category]: value,
    }));
  };

  const filterCheckedSkills = (skills) => {
    const result = [];

    // Itera sobre cada categoria de skills
    for (const category of skills) {
      // Para cada categoria, obtém o nome da categoria (primeira chave)
      const categoryName = Object.keys(category)[0];

      // Filtra apenas os itens checados dentro dessa categoria
      const checkedItems = category[categoryName].filter(
        (item) => item.checked === true
      );

      // Mapeia apenas id e level dos itens checados e adiciona ao resultado
      checkedItems.forEach((item) => {
        result.push({
          id: item.id,
          level: item.level,
        });
      });
    }

    return result || null;
  };

  const fetchTalents = async () => {
    setLoading(true);
    setTalents([]);
    try {
      const { data } = await api.post(`/companies/talents/search`, {
        data: {
          attributes: {
            knowledgeAreas:
              filters?.knowledge
                ?.filter((item) => item.checked)
                ?.map((conh) => ({
                  id: conh.id,
                  level: conh.level,
                })) || [],
            city: filters?.location?.city,
            state:
              brazilStates?.find(
                (item) => Number(item.id) === Number(filters?.location?.state)
              )?.sigla || "",
            minSalary: filters?.salary?.min,
            maxSalary: filters?.salary?.max,
            selfieLetterRequired: filters?.selfieLetter,
            predominantFit: filters?.primary,
            secondaryFit: filters?.secondary,
            balancedFit: filters?.balanced,
            skills: filterCheckedSkills(filters.skills),
          },
        },
      });

      const taletsLIts = data?.data.map((item) => ({
        id: item.id,
        ...item.attributes,
      }));

      setTalents(taletsLIts);

      // toast.success(`Nova senha enviada para o telefone com final`);
    } catch (error) {
      console.error("Error fetching talents:", error);
    }
    setLoading(false);
  };

  return (
    <AuthorizedLayoutCompanies>
      <div className="titlePage">Banco de talentos</div>
      <div className="talent-search-container">
        <SearchFilter
          handleFilterChange={handleFilterChange}
          filters={filters}
          setFilters={setFilters}
          fetchTalents={fetchTalents}
          brazilStates={brazilStates}
          loading={loading}
        />

        <div className="contentContainer" style={{ flex: 1 }}>
          <div className="conainerRight">
            <div className="talent-list-container" style={{minHeight: '690px'}}>
              <div className="headerTitle">
                <div className="title">Lista de talentos</div>

                <div
                  className="containerFilters"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <div className="text">
                    <strong>
                      {currentPage}-{totalPages}
                    </strong>{" "}
                    de <strong>{totalPages}</strong>
                  </div>
                  <div className="search-container">
                    <input
                      placeholder="Pesquisar talento"
                      variant="outlined"
                      size="small"
                      value={searchTerm}
                      onChange={handleSearch}
                    />

                    <img
                      src={iconSearch}
                      alt="sarch"
                      style={{ width: "30px" }}
                    />
                  </div>
                </div>
              </div>

              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "150px",
                    justifySelf: "center",
                    marginTop: "50px",
                  }}
                >
                  <LoadingIcon />
                </div>
              )}

              <div className="talent-list">
                {getCurrentItems()?.map((talent) => (
                  <TalentCard key={talent.id} talent={talent} />
                ))}
              </div>

              {getCurrentItems()?.length > 0 && (
                <div className="containerPainationPainel">
                  <button
                    onClick={prevPage}
                    disabled={currentPage === 1}
                    className=""
                  >
                    <img
                      src={iconLArrow}
                      alt="sarch"
                      // style={{ width: "8px", height: "12px" }}
                    />
                    Anterior
                  </button>

                  <span className="text">{currentPage}</span>

                  <button
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                  >
                    Próxima
                    <img
                      src={iconLArrow}
                      alt="sarch"
                      style={{ transform: "rotate(180deg)" }}
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AuthorizedLayoutCompanies>
  );
};

export default TalentSearch;
